import React from 'react';
import PropTypes from 'prop-types';

import MainHeader from '~/components/Header/Main';
import SideHeader from '~/components/Header/Side';

import { Wrapper, Body, Container, Main } from './styles';

export default function DefaultLayout({ children }) {
  return (
    <Wrapper>
      <MainHeader />
      <Body>
        <SideHeader />
        <Container>
          <Main>{children}</Main>
        </Container>
      </Body>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
