import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'div-input1 div-input2'
        'div-resume div-resume'
        'div-files div-files'
        'noffen button';
      grid-gap: 20px 15px;
      width: 100%;

      @media (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-areas:
          'div-input1'
          'div-input2'
          'div-resume'
          'div-resume'
          'div-files'
          'button';
      }

      & > div:nth-of-type(1) {
        grid-area: div-input1;
      }

      & > div:nth-of-type(2) {
        grid-area: div-input2;
      }

      & > div:nth-of-type(3) {
        display: flex;
        flex-direction: column;
        grid-area: div-resume;
        align-items: flex-start;

        span {
          margin: 10px 0 10px 5px;
        }
      }

      & > div:nth-of-type(4) {
        grid-area: div-files;

        @media (max-width: 900px) {
          display: flex;
          flex-direction: column;
        }

        div:first-of-type {
          display: flex;
          align-items: center !important;
          justify-content: space-between !important;

          @media (max-width: 900px) {
            margin-bottom: 20px;
          }

          label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 60px;
            cursor: pointer;
            border-radius: 8px;
            background: #323232;
            color: #fff;
            font-size: 20px;
            font-family: 'Montserrat', sans-serif;

            svg {
              margin-right: 7px;
            }
          }

          input[type='file'] {
            display: none;
          }
        }
      }

      & > div:nth-of-type(5) {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-end;
        justify-content: end;
        width: 100%;
        grid-area: button;
      }

      button {
        margin: 5px 0 0;
        height: 44px;
        padding: 0 10px;
        background: ${lighten(0.2, '#228b22')};
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        transition: background 0.2s;

        &:hover {
          background: ${lighten(0.1, '#228b22')};
        }
      }
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 106px;

    span {
      margin: 10px 0 10px 5px;
    }

    input + span {
      align-self: center;
      color: #b89b29;
      margin: 0;
    }
  }
`;

export const DivFiles = styled.div`
  display: flex;
  width: 100%;
  min-height: 300px;
  border-radius: 8px;
  background: #535353;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;

    li:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    li:last-of-type {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    li {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 13px 20px;
      background: #323232;
      transition: background 0.2s;
      position: relative;

      &:hover {
        background: ${lighten(0.03, '#323232')};
      }

      & + li::before {
        content: '';
        width: 90%;
        height: 3px;
        background: #fff;
        top: -1px;
        left: 5%;
        position: absolute;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        span {
          color: #fff;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background: #424242;
          margin-left: 10px;

          &:hover {
            background: ${lighten(0.1, '#424242')};
          }
        }
      }
    }
  }
`;
