import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  background: #323232;
  width: 100%;
  min-height: calc(100vh - 70px);
  max-width: 215px;
  padding: 30px 10px;

  @media (max-width: 800px) {
    max-width: 100%;
    height: auto;
    min-height: 0;
  }

  nav {
    width: 100%;

    ul {
      display: flex;
      flex-direction: column;
      width: 100%;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 40px;
        padding: 0 10px;
        transition: background 0.2s;

        &:hover {
          background: ${lighten(0.2, '#323232')};
        }

        & + li {
          margin-top: 10px;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: start;
          width: 100%;
          color: #fff;
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;

          @media (max-width: 800px) {
            justify-content: center;
          }

          svg {
            margin-right: 8px;
          }
        }
      }

      div {
        display: none;

        @media (max-width: 540px) {
          display: flex;
        }
      }
    }
  }
`;
