import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    form {
      max-width: 600px;
      width: 100%;

      button {
        align-self: flex-end;
        margin: 5px 0 0;
        padding: 0 10px;
        height: 44px;
        background: ${lighten(0.2, '#228b22')};
        font-weight: bold;
        color: #fff;
        border: 0;
        border-radius: 4px;
        font-size: 14px;
        transition: background 0.2s;

        &:hover {
          background: ${lighten(0.1, '#228b22')};
        }
      }
    }
  }
`;
