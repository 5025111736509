import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
  email: Yup.string().required('E-mail é obrigatório.'),
});

export default function Collab() {
  const [users, setUsers] = useState([]);
  const profile = useSelector(state => state.user.profile);
  const [moderator, setModerator] = useState(profile.id);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get('/users');

      setUsers(
        response.data.map(user => ({
          id: user.user.id,
          name: user.user.name,
          email: user.user.email,
        }))
      );
    }

    loadUsers();
  }, []);

  async function handleSubmit(data) {
    try {
      data = {
        ...data,
        password: '123456',
        type: 'collab',
      };

      const response = await api.post('/users', {
        ...data,
      });

      const { id } = response.data;

      await api.post('/relationusers', {
        moderator_id: moderator,
        user_id: id,
      });

      toast.success('Colaborador cadastrado com sucesso.');
      history.push('/members');
    } catch (err) {
      toast.error('Erro ao cadastrar cliente, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <select
            placeholder="Associado ao colaborador"
            onChange={e => setModerator(e.target.value)}
            value=""
          >
            <option value="">Selecione um usuário</option>
            <option value={profile.id}>
              {profile.name} - {profile.email} (Você)
            </option>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {user.name} - {user.email}
              </option>
            ))}
          </select>

          <button type="submit">Cadastrar colaborador</button>
        </Form>
      </div>
    </Container>
  );
}
