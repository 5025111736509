import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    font-size: 17;
    color: #323232;
    font-weight: 500;
    font-family: Poppins;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 200px;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const Note = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 744px;
  min-height: 50px;
  padding: 15px 20px;
  ${props =>
    props.unRead
      ? css`
          background: #fff;
          border: 1px solid #323232;
          pointer-events: none;
        `
      : css`
          background: #323232;

          &:hover {
            transform: scale(1.007);
            background: #424242;
            cursor: pointer;
          }
        `}
  transition: background 0.2s;

  & + div {
    border-top: none;
  }

  &:first-of-type {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  &:last-of-type {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 7%;

    h1 {
      font-size: 17px;
      font-weight: 500;
      font-family: Archivo;
      ${props =>
        props.unRead
          ? css`
              color: #323232;
            `
          : css`
              color: #fff;
            `}
    }

    h1:last-of-type {
      text-align: end;
    }
  }

  div:last-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;

    p {
      font-size: 15px;
      font-family: Archivo;
      font-weight: 400;
      ${props =>
        props.unRead
          ? css`
              color: #323232;
            `
          : css`
              color: #fff;
            `}
    }

    span {
      font-size: 15px;
      font-weight: 400;
      ${props =>
        props.unRead
          ? css`
              color: #999;
            `
          : css`
              color: #ddd;
            `}
    }
  }
`;

export const EmptyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
  border-bottom: 2px solid #b89b29;

  h1 {
    font-family: 'Roboto', sans-serif;
  }
`;
