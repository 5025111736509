import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import { MdChat, MdMail, MdAccountCircle, MdGroup } from 'react-icons/md';
import { toast } from 'react-toastify';
import io from 'socket.io-client';
import PropTypes from 'prop-types';

import api from '~/services/api';

import { signOut } from '~/store/modules/auth/actions';
import { Links, LinkHeader } from './styles';

export default function Menu({ color, direction }) {
  const [notes, setNotes] = useState(0);
  const [messages, setMessages] = useState(0);
  const { profile } = useSelector(state => state.user);
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  const getNotes = async () => {
    try {
      const response = await api.get('/notes', {
        params: {
          page: 1,
        },
      });

      const count = response.data.rows.filter(note => note.read === false);

      setNotes(count.length);
    } catch (err) {
      toast.error('Erro ao carregar notas!');
    }
  };

  const getMessages = async () => {
    try {
      let data = {};

      if (profile.type.type === 'client') {
        data = {
          client_id: profile.id,
        };
      } else {
        data = {
          user_id: profile.id,
        };
      }

      const response = await api.get(`/rooms`, {
        params: {
          ...data,
        },
      });

      const count = response.data.map(room => room.unreads);

      setMessages(count.reduce((a, b) => a + b, 0));
    } catch (err) {
      toast.error('Erro ao carregar notas!');
    }
  };

  const registerToSocket = () => {
    const socket = io('https://api.sislaconsultingbr.com.br', {
      transports: ['websocket'],
      query: {
        user: profile.id,
      },
    });

    socket.on('notes', async () => {
      const response = await api.get('/notes', {
        params: {
          page: 1,
        },
      });

      const count = response.data.rows.filter(note => note.read === false);

      setNotes(count.length);
    });

    socket.on('readMessages', async () => {
      let data = {};

      if (profile.type.type === 'client') {
        data = {
          client_id: profile.id,
        };
      } else {
        data = {
          user_id: profile.id,
        };
      }

      const response = await api.get(`/rooms`, {
        params: {
          ...data,
        },
      });

      const count = response.data.map(room => room.unreads);

      setMessages(count.reduce((a, b) => a + b, 0));
    });
  };

  useEffect(() => {
    getNotes();
    getMessages();
    registerToSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Links color={color} direction={direction}>
      <Link to="/profile">
        <MdAccountCircle color={color} size={23} />
        {profile.name.length > 18
          ? `${profile.name.substr(0, 18 - 1)}...`
          : profile.name}
      </Link>

      <LinkHeader to="/chat" notifications={messages}>
        <MdChat color={color} size={21} />
        Chat
      </LinkHeader>

      <LinkHeader to="/notes" notifications={notes}>
        <MdMail color={color} size={23} />
        Notas
      </LinkHeader>

      {(profile.type.type === 'owner' || profile.type.type === 'adm') && (
        <Link to="/members">
          <MdGroup color={color} size={23} />
          Membros
        </Link>
      )}

      <button onClick={handleSignOut}>
        <FiLogOut color={color} size={21} />
        Sair
      </button>
    </Links>
  );
}

Menu.defaultProps = {
  color: '#000000',
  direction: 'row',
};

Menu.propTypes = {
  color: PropTypes.string,
  direction: PropTypes.string,
};
