/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { parseISO, format, differenceInCalendarDays, isBefore } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MdSend } from 'react-icons/md';
import { IoMdArrowRoundBack } from 'react-icons/io';
import io from 'socket.io-client';
import PropTypes from 'prop-types';

import api from '~/services/api';

import {
  Rooms,
  Room,
  ChatContainer,
  ChatContent,
  Messages,
  Message,
  Send,
} from './styles';
import Loader from '~/components/Loader';

export default function Chat({ demand_id }) {
  const [rooms, setRooms] = useState([]);
  const [activeRoom, setActiveRoom] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loaderRooms, setLoaderRooms] = useState(true);
  const [loaderMessages, setLoaderMessages] = useState(false);
  const [reload, setReload] = useState(false);
  const profile = useSelector(state => state.user.profile);
  const ulRef = useRef(null);
  const spanRef = useRef(null);

  const readMessages = async () => {
    try {
      await api.put(`/messages/${activeRoom}`);

      if (rooms.length > 0)
        setRooms(
          rooms.map(room => {
            if (
              room.room_id === activeRoom &&
              room.recipient.id !== profile.id &&
              room.message
            )
              room.message.read = true;

            return room;
          })
        );
    } catch (err) {
      toast.error('Erro ao atualizar mensagens!');
    }
  };

  const formatDate = date => {
    const difference = differenceInCalendarDays(new Date(), parseISO(date));
    let time = '';

    if (difference < 1)
      time = format(parseISO(date), "'Hoje às ' HH':'mm", { locale: pt });
    else if (difference >= 1 && difference < 2)
      time = format(parseISO(date), "'Ontem às ' HH':'mm", { locale: pt });
    else if (difference >= 2 && difference <= 6) {
      time = format(parseISO(date), "eee' às ' HH':'mm", { locale: pt });
      time = time.charAt(0).toUpperCase() + time.slice(1);
    } else
      time = format(parseISO(date), "dd'/'MM'/'Y', ás 'HH':'mm", {
        locale: pt,
      });

    return time;
  };

  const getRooms = async () => {
    setLoaderRooms(true);

    try {
      if (activeRoom) readMessages();

      let data = {};

      if (profile.type.type === 'client') {
        if (demand_id) {
          data = {
            client_id: profile.id,
            demand_id,
          };
        } else {
          data = {
            client_id: profile.id,
          };
        }
      } else if (profile.type.type === 'collab') {
        if (demand_id) {
          data = {
            demand_id,
          };
        }
      } else if (profile.type.type === 'adm') {
        if (demand_id) {
          data = {
            user_id: profile.id,
            demand_id,
          };
        } else {
          data = {
            user_id: profile.id,
          };
        }
      }

      const response = await api.get(`/rooms`, {
        params: {
          ...data,
        },
      });

      response.data.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        isBefore(parseISO(a.message.createdAt), parseISO(b.message.createdAt))
          ? 1
          : isBefore(
              parseISO(b.message.createdAt),
              parseISO(a.message.createdAt)
            )
          ? -1
          : 0
      );

      setRooms(
        response.data.map(room => ({
          ...room,
          last_message: room.message.createdAt
            ? formatDate(room.message.createdAt)
            : '',
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar conversas!');
    } finally {
      setLoaderRooms(false);
    }
  };

  const getMessages = async () => {
    try {
      const response = await api.get(`/messages/${activeRoom}`);

      setMessages(
        response.data.map(message => ({
          ...message,
          date: formatDate(message.created_at),
        }))
      );
      ulRef.current.scrollTop = ulRef.current.scrollHeight;
    } catch (err) {
      toast.error('Erro ao carregar mensagens!');
    } finally {
      setLoaderMessages(false);
    }
  };

  const newMessage = async () => {
    try {
      const content = spanRef.current.innerText;

      if (content !== '' && activeRoom) {
        const response = await api.post('/messages', {
          room_id: activeRoom,
          user_id: profile.id,
          content,
        });

        const { data } = response;

        setMessages(oldState => [
          {
            ...data,
            date: formatDate(data.created_at),
          },
          ...oldState,
        ]);

        const [room] = rooms.filter(item => item.room_id === activeRoom);
        const oldState = rooms.filter(item => item.room_id !== activeRoom);

        setRooms([
          {
            ...room,
            message: { ...data },
            last_message: formatDate(data.created_at),
          },
          ...oldState,
        ]);

        ulRef.current.scrollTop = ulRef.current.scrollHeight;
        spanRef.current.innerText = '';
      }
    } catch (err) {
      toast.error('Erro ao cadastrar mensage!');
    }
  };

  const registerToSocket = () => {
    const socket = io('https://api.sislaconsultingbr.com.br', {
      transports: ['websocket'],
      query: {
        user: profile.id,
      },
    });

    socket.on('message', MessageNew => {
      setMessages(oldState => [
        {
          ...MessageNew,
          date: formatDate(MessageNew.created_at),
        },
        ...oldState,
      ]);

      setActiveRoom(oldState => {
        if (MessageNew.room_id === oldState) {
          MessageNew.read = true;
          ulRef.current.scrollTop = ulRef.current.scrollHeight;
          setReload(true);
        }
        return oldState;
      });

      setRooms(oldState => {
        const [room] = oldState.filter(
          item => item.room_id === MessageNew.room_id
        );
        const state = oldState.filter(
          item => item.room_id !== MessageNew.room_id
        );

        return [
          {
            ...room,
            message: { ...MessageNew },
            last_message: formatDate(MessageNew.created_at),
          },
          ...state,
        ];
      });

      setReload(false);
    });
  };

  useEffect(() => {
    getRooms();
    registerToSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeRoom) {
      setLoaderMessages(true);
      readMessages();
      getMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRoom]);

  useEffect(() => {
    if (reload) readMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const currentRoom = useMemo(() => {
    if (rooms.length > 0 && activeRoom) {
      const room = rooms.filter(item => item.room_id === Number(activeRoom))[0];

      return room;
    }

    return null;
  }, [activeRoom, rooms]);

  return (
    <>
      <Rooms active={!!activeRoom}>
        <ul>
          {loaderRooms ? (
            <Loader size="55px" />
          ) : (
            rooms.map(room => (
              <Room
                key={room.room_id}
                unRead={
                  room.message && room.message.user_id !== profile.id
                    ? !room.message.read
                    : false
                }
                onClick={() => setActiveRoom(room.room_id)}
                active={room.room_id === Number(activeRoom)}
              >
                <div>
                  <p>
                    {room.recipient.name} - #{room.room.demand.id}
                  </p>
                  <span>{room.last_message ? room.last_message : ''}</span>
                </div>

                {room.message && (
                  <p>
                    {room.message.user_id === profile.id && 'Você: '}
                    {room.message.content}
                  </p>
                )}
              </Room>
            ))
          )}
        </ul>
      </Rooms>

      <ChatContainer active={activeRoom ? true : null}>
        <header>
          {activeRoom && (
            <button onClick={() => setActiveRoom(null)}>
              <IoMdArrowRoundBack color="#323232" size={20} />
            </button>
          )}

          {currentRoom && (
            <>
              <h1>{currentRoom.recipient.name} - </h1>
              <Link to={`/demands/${currentRoom.room.demand.id}/chat`}>
                {currentRoom.room.demand.type} |{' '}
                {currentRoom.room.demand.complainer} |{' '}
                {currentRoom.room.demand.process_number}
              </Link>
            </>
          )}
        </header>

        {activeRoom && (
          <ChatContent>
            <Messages>
              <ul ref={ulRef}>
                {loaderMessages ? (
                  <Loader size="55px" />
                ) : (
                  messages.map(message => (
                    <Message
                      you={profile.id === message.user.id}
                      key={message.id}
                    >
                      <p>{message.content}</p>
                      <span>{message.date}</span>
                    </Message>
                  ))
                )}
              </ul>
            </Messages>

            <Send>
              <span role="textbox" ref={spanRef} contentEditable />

              <button onClick={() => newMessage()}>
                <MdSend color="#000000" size={25} />
              </button>
            </Send>
          </ChatContent>
        )}
      </ChatContainer>
    </>
  );
}

Chat.defaultProps = {
  demand_id: null,
};

Chat.propTypes = {
  demand_id: PropTypes.number,
};
