import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
  email: Yup.string().required('E-mail é obrigatório.'),
});

export default function Collab() {
  const [clients, setClients] = useState([]);
  const [idClient, setIdClients] = useState(null);

  useEffect(() => {
    async function loadClients() {
      const response = await api.get('/clients');

      setClients(
        response.data.map(client => ({
          id: client.id,
          name: client.name,
        }))
      );
    }
    loadClients();
  }, []);

  async function handleSubmit(data) {
    try {
      if (idClient) {
        data = {
          ...data,
          password: '123456',
          type: 'client',
        };

        const response = await api.post('/users', {
          ...data,
        });

        await api.post('/users/clients', {
          client_id: idClient,
          user_id: response.data.id,
        });

        toast.success('Usuário cadastrado com sucesso.');
        history.push('/members');
      } else {
        toast.warn('Confira os dados.');
      }
    } catch (err) {
      toast.error('Erro ao cadastrar cliente, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <select
            placeholder="Cliente"
            onChange={e => setIdClients(e.target.value)}
          >
            <option value="">Selecione um cliente</option>
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>

          <button type="submit">Cadastrar usuário</button>
        </Form>
      </div>
    </Container>
  );
}
