import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const CreateModal = styled.div`
  ${props =>
    props.show
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  flex-direction: column;
  position: absolute;
  width: 400px;
  background: #323232;
  border-radius: 8px;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;

  @media (max-width: 450px) {
    width: 100%;
    max-width: calc(100vw - 60px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: #b89b29;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-left: 1px solid #b89b29;
  border-right: 1px solid #b89b29;

  h2 {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;

  p {
    font-size: 15px;
    color: #fff;
    text-align: justify;
  }

  div {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid #fff;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  min-height: 40px;
  padding: 20px 20px 0 20px;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    max-width: 50px;
    height: 40px;
    padding: 0 10px;
    font-size: 15px;
    font-weight: bold;
    background: ${lighten(0.2, '#228b22')};
    border: 0;
    border-radius: 4px;

    &:hover {
      background: ${lighten(0.1, '#228b22')};
    }
  }

  button + button {
    margin-left: 15px;
    background: ${lighten(0.05, '#ff1919')};
    border: 0;
    border-radius: 4px;

    &:hover {
      background: ${lighten(0.01, '#ff1919')};
    }
  }
`;
