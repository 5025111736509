import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MdSearch, MdList } from 'react-icons/md';

import history from '~/services/history';

import { updateSearchRequest } from '~/store/modules/user/actions';
import { Container, Content, SearchBox } from './styles';
import Menu from '~/components/Header/Menu';

import logo from '~/assets/logo.png';

export default function Header() {
  const [search, setSearch] = useState('');
  const [empty, setEmpty] = useState(false);
  const [menu, setMenu] = useState('open');
  const dispatch = useDispatch();

  function handleChangeInput(value) {
    setSearch(value);
    if (search.length > 0) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
  }

  function handleSearch() {
    if (search.length > 0) {
      dispatch(updateSearchRequest(search));
      setSearch('');
      setEmpty(false);
      history.push('/demands');
    }
  }

  return (
    <Container className={menu}>
      <Content>
        <div>
          <button
            type="button"
            onClick={() => setMenu(menu === 'open' ? '' : 'open')}
          >
            <MdList color="#000000" size={26} />
          </button>

          <img src={logo} alt="logo" />

          <SearchBox hasEmpty={empty}>
            <input
              type="search"
              placeholder="Pesquise"
              value={search}
              onChange={e => handleChangeInput(e.target.value)}
              onFocus={() => setEmpty(true)}
              onBlur={() => handleChangeInput(search)}
            />

            <button type="button" onClick={handleSearch}>
              <MdSearch />
            </button>
          </SearchBox>
        </div>

        <aside>
          <Menu />
        </aside>
      </Content>
    </Container>
  );
}
