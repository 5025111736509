import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;

  form {
    button {
      align-self: flex-end;
      margin: 5px 0 0;
      height: 44px;
      min-width: 120px;
      background: #00a000;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.07, '#00a000')};
      }
    }
  }
`;
