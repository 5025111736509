import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  text-align: center;

  h1 {
    font-weight: bold;
    color: #b89b29;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
  }
`;
