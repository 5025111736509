import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
});

export default function Collab({ match }) {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [id] = useState(match.params.id ? match.params.id : false);
  const [newModerator, setNewModerator] = useState(0);
  const [prevModerator, setPrevModerator] = useState({});
  const profile = useSelector(state => state.user.profile);

  useEffect(() => {
    async function loadUsers() {
      const response = await api.get(`/users/${id}/details`);

      setUser(response.data);

      const responseRelation = await api.get(`/relationusers/${id}`);

      setPrevModerator(responseRelation.data);
      setNewModerator(responseRelation.data.moderator_id);

      const responseUsers = await api.get('/users');
      setUsers(
        responseUsers.data.map(item => ({
          id: item.user.id,
          name: item.user.name,
          email: item.user.email,
        }))
      );
    }

    loadUsers();
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/users/${id}`, {
        ...data,
      });

      await api.put(`/relationusers/${prevModerator.id}`, {
        moderator_id: newModerator,
      });

      toast.success('Colaborador atualizado com sucesso.');
      history.push('/members');
    } catch (err) {
      toast.error('Erro ao atualizar colaborador, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={user} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <select
            placeholder="Associado ao colaborador"
            onChange={e => setNewModerator(e.target.value)}
            value={newModerator}
          >
            <option value={profile.id}>
              {profile.name} - {profile.email} (Você)
            </option>
            {users.map(item => (
              <option key={item.id} value={item.id}>
                {item.name} - {item.email}
              </option>
            ))}
          </select>

          <button type="submit">Atualizar colaborador</button>
        </Form>
      </div>
    </Container>
  );
}

Collab.propTypes = {
  match: PropTypes.object.isRequired,
};
