import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string(),
  email: Yup.string(),
});

export default function User({ match }) {
  const [clients, setClients] = useState([]);
  const [user, setUser] = useState({});
  const [currentClient, setCurrentClient] = useState(0);
  const [id] = useState(match.params.id ? match.params.id : false);

  useEffect(() => {
    async function loadClients() {
      const response = await api.get(`/users/${id}/details`);

      setUser(response.data);

      const responseClient = await api.get(`/users/${id}/clients`);

      setCurrentClient(responseClient.data.client_id);

      const responseClients = await api.get('/clients');
      setClients(
        responseClients.data.map(client => ({
          id: client.id,
          name: client.name,
        }))
      );
    }
    loadClients();
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/users/${id}`, {
        ...data,
      });

      await api.put(`/users/${id}/clients`, {
        client_id: currentClient,
      });

      toast.success('Usuário atualizado com sucesso.');
      history.push('/members');
    } catch (err) {
      toast.error('Erro ao atualizar usuário, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={user} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome completo" />
          <Input name="email" type="email" placeholder="E-mail" />
          <select
            placeholder="Cliente"
            onChange={e => setCurrentClient(e.target.value)}
            value={currentClient}
          >
            {clients.map(client => (
              <option key={client.id} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>

          <button type="submit">Atualizar usuário</button>
        </Form>
      </div>
    </Container>
  );
}

User.propTypes = {
  match: PropTypes.object.isRequired,
};
