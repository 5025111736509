import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';

export const Links = styled.div`
  display: flex;
  ${props =>
    props.direction === 'row'
      ? css`
          justify-content: space-around;
          align-items: center;
        `
      : css`
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
        `}

  width: 100%;

  a,
  button {
    display: flex;
    ${props =>
      props.direction === 'row'
        ? css`
            justify-content: center;
            align-items: center;
          `
        : css`
            align-items: center;
            justify-content: start;
            min-height: 40px;
            padding: 0 10px;
            transition: background 0.2s;

            @media (max-width: 800px) {
              justify-content: center;
            }

            &:hover {
              background: ${lighten(0.2, '#323232')};
            }

            & + li {
              margin-top: 10px;
            }
          `}
    width: 100%;
    height: 100%;
    ${props =>
      props.color &&
      css`
        color: ${props.color};
      `};
    font-size: 16px;
    font-weight: bold;

    svg {
      margin-right: 8px;
    }
  }

  button {
    background: none;
    border: none;

    &:hover {
      color: #b89b29 !important;

      svg {
        color: #b89b29 !important;
        transform: scale(1.15);
      }
    }
  }
`;

export const LinkHeader = styled(Link)`
  position: relative;

  ${props =>
    props.notifications > 0 &&
    css`
      &::before {
        content: '${props.notifications}';
        display: flex;
        align-items: center;
        justify-content: center;
        background: #b89b29;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;
        font-size: 15px;
        font-weight: 400;
        font-family: Archivo;
        position: absolute;
        top: 15%;
        left: 75%;
      }
    `}
`;
