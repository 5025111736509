import React from 'react';
import PropTypes from 'prop-types';

import { CustomButton } from './styles';

export default function Custom({ func, text, children }) {
  return (
    <CustomButton onClick={func}>
      {children}
      {text}
    </CustomButton>
  );
}

Custom.defaultProps = {
  children: null,
};

Custom.propTypes = {
  func: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.element,
};
