import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  box-shadow: 0 10px 10px 5px rgba(0, 0, 0, 0.4);
  z-index: 0;
  border: 1px solid #b89b29;
  border-radius: 4px;
`;
