import styled from 'styled-components';
import { lighten } from 'polished';

export const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  background: ${lighten(0.3, '#000000')};
  border: 0;
  border-radius: 4px;

  &:hover {
    background: ${lighten(0.2, '#000000')};
  }
`;
