import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import Default from '../pages/Default';
import Profile from '../pages/Profile';

import Dashboard from '~/pages/Dashboard';

import Chat from '~/pages/Chat';

import Notes from '~/pages/Notes';

import ListMembers from '~/pages/Members/List';
import CreateClient from '~/pages/Members/Create/Client';
import CreateUser from '~/pages/Members/Create/User';
import CreateCollab from '~/pages/Members/Create/Collab';
import EditClient from '~/pages/Members/Edit/Client';
import EditUser from '~/pages/Members/Edit/User';
import EditCollab from '~/pages/Members/Edit/Collab';

import ListDemands from '~/pages/Demands/List';
import CreateDemands from '~/pages/Demands/Create';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/profile" exact component={Profile} isPrivate />

      <Route path="/chat" exact component={Chat} isPrivate />

      <Route path="/notes" exact component={Notes} isPrivate />

      <Route path="/dashboard" exact component={Default} isPrivate />

      <Route path="/dashboard/reports" component={Dashboard} isPrivate />

      <Route
        path="/members"
        exact
        component={ListMembers}
        isPrivate
        isProtectAdm
      />

      <Route
        path="/members/create/client"
        component={CreateClient}
        isPrivate
        isProtectAdm
      />
      <Route
        path="/members/create/user"
        component={CreateUser}
        isPrivate
        isProtectAdm
      />
      <Route
        path="/members/create/collab"
        component={CreateCollab}
        isPrivate
        isProtectAdm
      />

      <Route
        path="/members/edit/:id/client"
        component={EditClient}
        isPrivate
        isProtectAdm
      />
      <Route
        path="/members/edit/:id/user"
        component={EditUser}
        isPrivate
        isProtectAdm
      />
      <Route
        path="/members/edit/:id/collab"
        component={EditCollab}
        isPrivate
        isProtectAdm
      />

      <Route path="/demands" exact component={ListDemands} isPrivate />
      <Route path="/demands/:id/chat" component={ListDemands} isPrivate />
      <Route path="/demands/:search/search" component={ListDemands} isPrivate />

      <Route
        path="/demands/create"
        component={CreateDemands}
        isPrivate
        isProtectClient
      />
    </Switch>
  );
}
