import React from 'react';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório.'),
});

export default function Client() {
  async function handleSubmit(data) {
    try {
      data = {
        ...data,
      };

      await api.post('/clients', {
        ...data,
      });

      toast.success('Cliente cadastrado com sucesso.');
      history.push('/members');
    } catch (err) {
      toast.error('Erro ao cadastrar cliente, confira os dados!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome" />

          <button type="submit">Cadastrar cliente</button>
        </Form>
      </div>
    </Container>
  );
}
