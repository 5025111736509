import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .min(6, 'No mínimo 6 caracteres')
    .required('A senha é obrigatória'),
});
/*
const awsGet = () => {
  const filename =
    's3://jus-dossier/5f9249b04658a400158cc73b/5ff0e7daf49042000a5d50c4-0000277-83.2016.5.05.0133/lawsuits/9f8f07ab-automatic:16042824-00002778320165050133-lawsuit-2.json';

  const s3 = new AWS.S3({
    accessKeyId: 'AKIARMMD5JEACE26OB7X',
    secretAccessKey: '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',
  });

  const { response } = s3.getObject({ Bucket: 'aws-file', Key: filename });

  console.log(response);
};

awsGet(); */

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  function handleSubmit() {
    dispatch(signInRequest(email, password));
  }
  return (
    <Form schema={schema} onSubmit={handleSubmit}>
      <div>
        <Input
          type="text"
          name="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <p className={email !== '' ? 'active' : ''}>E-mail</p>
      </div>

      <div>
        <Input
          type="password"
          name="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <p className={password !== '' ? 'active' : ''}>Senha</p>
      </div>

      <button
        type="submit"
        className={email !== '' && password !== '' ? 'enable' : 'disable'}
      >
        {loading ? 'Carregando...' : 'Acessar Minha Conta'}
      </button>
    </Form>
  );
}

export default SignIn;
