import React from 'react';
import { MdDeleteForever } from 'react-icons/md';

import { DeleteButton } from './styles';

export default function Edit({ func }) {
  return (
    <DeleteButton onClick={func}>
      <MdDeleteForever color="#fff" size={23} />
    </DeleteButton>
  );
}
