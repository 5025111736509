/* eslint-disable no-plusplus */
import React, { useState, useEffect, useMemo } from 'react';
import { format, parseISO, addDays } from 'date-fns';
import { pt } from 'date-fns/locale';
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { FaFileExcel } from 'react-icons/fa';

import api from '~/services/api';

import Table from '~/components/Table';
import Loader from '~/components/Loader';
import CustomButton from '~/components/Form/Button/Custom';

import {
  Container,
  ChartBar,
  DateFilterProcess,
  CourtContainer,
  LoaderContainer,
} from './styles';

export default function Dashboard() {
  const [allProcess, setAllProcess] = useState([]);
  const [finishedProcess, setFinishedProcess] = useState([]);
  const [barChartData, setBarChartData] = useState({});
  const [initialDateProcess, setInitialDateProcess] = useState('');
  const [finalDateProcess, setFinalDateProcess] = useState('');
  const [search, setSearch] = useState('');
  const [allPrice, setAllPrice] = useState([]);
  const [horizontalBarChartData, setHorizontalBarChartData] = useState({});
  const [courts, setCourts] = useState([]);
  const [excel, setExcel] = useState([]);
  const [loader, setLoader] = useState(true);

  const arrayUnique = array => {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i] === a[j]) a.splice(j--, 1);
      }
    }

    return a;
  };

  const exportExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'relatorio';

    const ws = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getProcess = async () => {
    try {
      const response = await api.get('/dashboard/process', {
        params: {
          initialDate: initialDateProcess,
          finalDate: finalDateProcess,
          search,
        },
      });

      const allYears = response.data.all.map(process => {
        return format(parseISO(process.year), 'yyyy');
      });

      const allData = response.data.all.map(process => {
        return Number(process.count);
      });

      const finishedYears = response.data.finished.map(process => {
        return format(parseISO(process.year), 'yyyy');
      });

      const finishedData = response.data.finished.map(process => {
        return Number(process.count);
      });

      setAllProcess(allData);
      setFinishedProcess(finishedData);

      setBarChartData({
        labels: arrayUnique(allYears.concat(finishedYears)),
        datasets: [
          {
            label: 'Demandas Criadas',
            backgroundColor: '#000000',
            data: allData,
          },
          {
            label: 'Demandas Finalizadas',
            backgroundColor: '#b89b29',
            data: finishedData,
          },
        ],
      });
    } catch (error) {
      toast.error('Erro ao carregar valores!');
    }
  };

  const getMoney = async () => {
    try {
      const response = await api.get('/dashboard/moneys', {
        params: {
          initialDate: initialDateProcess,
          finalDate: finalDateProcess,
          search,
        },
      });

      const moneys = response.data.map(item => item.money);

      const data = response.data.map(item => Number(item.sum));

      setAllPrice(data);

      setHorizontalBarChartData({
        labels: moneys,
        datasets: [
          {
            label: 'Valores',
            backgroundColor: '#000000',
            data,
          },
        ],
      });
    } catch (error) {
      toast.error('Erro ao carregar valores!');
    }
  };

  const getCourts = async () => {
    setLoader(true);
    try {
      const response = await api.get('/dashboard/courts', {
        params: {
          initialDate: initialDateProcess,
          finalDate: finalDateProcess,
          search,
        },
      });

      setCourts(response.data);

      const responseExcel = await api.get('/dashboard/excel', {
        params: {
          initialDate: initialDateProcess,
          finalDate: finalDateProcess,
          search,
        },
      });

      setExcel(
        responseExcel.data.map(item => ({
          Id: item.demand.id,
          Tipo: item.demand.type,
          Prazo: format(
            addDays(parseISO(item.demand.deadline), 1),
            "dd'/'MM'/'Y'"
          ),
          Comarca: item.demand.country,
          N_Processo: item.demand.process_number,
          Reclamante: item.demand.complainer,
          Reclamada: item.demand.claimed,
          Forum_Tribunal: item.demand.forum_tribune,
          Vara: item.demand.vara,
          Cliente: item.demand.user.name,
          Usuario: item.demand.moderator.name,
          Solicitado: format(parseISO(item.demand.createdAt), "dd'/'MM'/'Y'", {
            locale: pt,
          }),
          Resumo: item.demand.resume,
          Status: item.demand.status,
          Colaborador: item.demand.collab.name,
          Verba: item.money,
          Valor: item.price,
        }))
      );
    } catch (error) {
      toast.error('Erro ao carregar valores!');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateProcess, finalDateProcess, search]);

  useEffect(() => {
    getMoney();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateProcess, finalDateProcess, search]);

  useEffect(() => {
    getCourts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDateProcess, finalDateProcess, search]);

  const maxNumberProcess = useMemo(() => {
    let concatArray = [];

    if (allProcess.length > 0 && finishedProcess.length > 0) {
      concatArray = allProcess.concat(finishedProcess);
    } else if (allProcess.length > 0) {
      concatArray = allProcess;
    } else if (finishedProcess > 0) {
      concatArray = finishedProcess;
    }

    let max = 10;

    if (concatArray.length > 0) {
      max = concatArray.reduce((a, b) => {
        return Math.max(a, b);
      });
    }

    return Math.round(max * 1.15);
  }, [allProcess, finishedProcess]);

  const stepSizeProcess = useMemo(() => {
    return Math.ceil(maxNumberProcess / 10, 1);
  }, [maxNumberProcess]);

  const maxNumberPrice = useMemo(() => {
    let max = 10;

    if (allPrice.length > 0) {
      max = allPrice.reduce((a, b) => {
        return Math.max(a, b);
      });
    }

    return Math.round(max * 1.15);
  }, [allPrice]);

  const stepSizePrice = useMemo(() => {
    return Math.ceil(maxNumberPrice / 10, 1);
  }, [maxNumberPrice]);

  const header = [
    'Tribunais Trabalhistas',
    'Inicial',
    'Setença',
    'Acórdão',
    'Execução',
  ];

  return (
    <Container>
      <ChartBar>
        <DateFilterProcess>
          <div>
            <input
              type="text"
              value={search}
              onChange={e => setSearch(e.target.value)}
              placeholder="Busca por nº processo // Busca por reclamante"
            />
          </div>

          <div>
            <input
              type="date"
              value={initialDateProcess}
              onChange={e => setInitialDateProcess(e.target.value)}
            />

            <span>até</span>

            <input
              type="date"
              value={finalDateProcess}
              onChange={e => setFinalDateProcess(e.target.value)}
            />
          </div>
        </DateFilterProcess>

        <Bar
          data={barChartData}
          options={{
            title: {
              display: true,
              text: 'Número de Demandas por Ano',
              fontSize: 20,
            },
            width: '900',
            height: '400',
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: maxNumberProcess,
                    min: 0,
                    stepSize: stepSizeProcess,
                  },
                },
              ],
              xAxes: [
                {
                  stacked: true,
                },
              ],
            },
          }}
        />
      </ChartBar>

      <ChartBar>
        <HorizontalBar
          data={horizontalBarChartData}
          options={{
            legend: {
              display: true,
              position: 'top',
            },
            width: '900',
            height: '700',
            responsive: true,
            maintainAspectRatio: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    max: maxNumberPrice,
                    min: 0,
                    stepSize: stepSizePrice,
                  },
                },
              ],
            },
            scaleLabel: label => {
              return ` $${label.value
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
            },
          }}
        />
      </ChartBar>

      <CourtContainer>
        {loader ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <Table title="" header={header} data={courts} />

            <CustomButton func={exportExcel} text="EXPORTAR PARA EXCEL">
              <FaFileExcel color="#fff" size={18} />
            </CustomButton>
          </>
        )}
      </CourtContainer>
    </Container>
  );
}
