import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BsListTask } from 'react-icons/bs';
import { MdCreate } from 'react-icons/md';
import { AiFillPieChart } from 'react-icons/ai';

import { Container } from './styles';
import Menu from '~/components/Header/Menu';

export default function Header() {
  const { profile } = useSelector(state => state.user);
  return (
    <Container id="menu">
      <nav>
        <ul>
          {profile.type.type === 'client' && (
            <li>
              <Link to="/demands/create">
                <MdCreate color="#fff" size={23} />
                Criar Demandas
              </Link>
            </li>
          )}
          <li>
            <Link to="/demands">
              <BsListTask color="#fff" size={23} />
              Minhas Demandas
            </Link>
          </li>

          <li>
            <Link to="/dashboard/reports">
              <AiFillPieChart color="#fff" size={23} />
              Dashboard
            </Link>
          </li>

          <Menu color="#fff" direction="column" />
        </ul>
      </nav>
    </Container>
  );
}
