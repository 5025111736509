/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { MdClose } from 'react-icons/md';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';

import api from '~/services/api';
import history from '~/services/history';

import { Container, DivInput, DivFiles } from './styles';

const schema = Yup.object().shape({
  deadline: Yup.string().required('Data é obrigatório.'),
  process_number: Yup.string().required('Número do processo é obrigatório.'),
  country: Yup.string(),
  complainer: Yup.string(),
  claimed: Yup.string(),
  forum_tribune: Yup.string(),
  vara: Yup.string(),
});

export default function Create() {
  const [type, setType] = useState('');
  const [resume, setResume] = useState('');
  const [files, setFiles] = useState([]);
  const [cnj, setCnj] = useState('');
  const profile = useSelector(state => state.user.profile);
  const fileRef = useRef(null);

  async function handleSubmit(data) {
    try {
      if (!type) {
        toast.error('Confira os dados!');
      } else {
        data = {
          type,
          resume,
          ...data,
          user_id: profile.id,
        };

        const response = await api.post('/demands', {
          ...data,
        });

        const { id, moderator_id } = response.data;

        if (files.length > 0) {
          files.map(async file => {
            const content = new FormData();

            content.append('file', file);
            content.append('type', 'attachment');

            await api.post(`/files/${id}`, content);
          });
        }

        await api.post(`/notes/${id}`);

        await api.post('/rooms', {
          demand_id: id,
          client_id: profile.id,
          user_id: moderator_id,
        });

        toast.success('Demanda cadastrada com sucesso.');
        history.push('/demands');
      }
    } catch (err) {
      toast.error('Erro ao cadastrar demanda, confira os dados!');
    }
  }

  const attachFile = value => {
    fileRef.current.value = null;
    setFiles([...files, value]);
  };

  const removeFile = value => {
    setFiles(files.filter(item => item !== value));
  };

  const getDossierFiles = async () => {
    try {
      if (cnj !== '') {
        await api.get('/dossier', {
          params: {
            cnj,
          },
        });
      }
    } catch (err) {
      toast.warn(
        'Dados do processo não encontrados, por favor confira o número do processo informado.'
      );
    }
  };

  return (
    <Container>
      <div>
        <Form schema={schema} onSubmit={handleSubmit}>
          <DivInput>
            <div>
              <span>Tipo de demanda:</span>
              <select
                name="type"
                defaultValue={type}
                onChange={e => setType(e.target.value)}
              >
                <option value="">Selecione</option>
                <option value="Liquidação da Inicial">
                  Liquidação da Inicial
                </option>
                <option value="Liquidação da Sentença">
                  Liquidação da Sentença
                </option>
                <option value="Liquidação do Acórdão">
                  Liquidação do Acórdão
                </option>
                <option value="Liquidação (Execução)">
                  Liquidação (Execução)
                </option>
                <option value="Impugnação">Impugnação</option>
                <option value="Imp. aos Esclarecimentos">
                  Imp. aos Esclarecimentos
                </option>
                <option value="Embargos à Execução">Embargos à Execução</option>
                <option value="Man. ao Laudo Pericial">
                  Man. ao Laudo Pericial
                </option>
                <option value="Agravo de Petição">Agravo de Petição</option>
                <option value="Elaboração de Quesitos">
                  Elaboração de Quesitos
                </option>
                <option value="Discriminação de Verbas">
                  Discriminação de Verbas
                </option>
                <option value="Atualização">Atualização</option>
              </select>
            </div>

            <div>
              <span>Prazo:</span>
              <Input
                name="deadline"
                type="date"
                placeholder="Data e Hora da Demanda"
              />
            </div>

            <div>
              <span>Número do Processo:</span>
              <Input
                name="process_number"
                placeholder="0000000-00.0000.0.00.0000"
                onBlur={() => getDossierFiles()}
                onChange={e => setCnj(e.target.value)}
              />
            </div>

            <div>
              <span>Comarca:</span>
              <Input name="country" placeholder="Comarca onde será a Demanda" />
            </div>
          </DivInput>

          <DivInput>
            <div>
              <span>Reclamante:</span>
              <Input name="complainer" />
            </div>

            <div>
              <span>Reclamada:</span>
              <Input name="claimed" />
            </div>

            <div>
              <span>Foro/Tribunal:</span>
              <Input name="forum_tribune" />
            </div>

            <div>
              <span>Vara:</span>
              <Input name="vara" />
            </div>
          </DivInput>

          <div>
            <span>Resumo:</span>
            <textarea
              name="resume"
              placeholder="Resumo / Detalhes da demanda"
              value={resume}
              onChange={e => setResume(e.target.value)}
            />
          </div>

          <div>
            <DivInput>
              <input
                ref={fileRef}
                name="file"
                type="file"
                id="file"
                placeholder="Anexos"
                onChange={e => attachFile(e.target.files[0])}
              />
              <label htmlFor="file">
                <BsFileEarmarkArrowUp color="#fff" size={22} />
                Anexos
              </label>
            </DivInput>

            <DivFiles>
              <ul>
                {files.map(file => (
                  <li key={`${file.name}-${file.size}`}>
                    <div>
                      <span>{file.name}</span>
                      <button type="button" onClick={() => removeFile(file)}>
                        <MdClose color="#fff" size={25} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </DivFiles>
          </div>

          <div>
            <button type="submit">Cadastrar demanda</button>
          </div>
        </Form>
      </div>
    </Container>
  );
}
