import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import roundTo from 'round-to';

import api from '~/services/api';

import Loader from '~/components/Loader';
import Pagination from '~/components/Pagination';

import {
  Container,
  LoaderContainer,
  NotesContainer,
  Note,
  EmptyDiv,
} from './styles';

export default function Notes() {
  const [notes, setNotes] = useState([]);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const changePage = num => {
    setPage(num + 1);
  };

  const getNotes = async () => {
    setLoader(true);

    try {
      const response = await api.get('/notes', {
        params: {
          page,
        },
      });

      setNotes(
        response.data.rows.map(note => ({
          ...note,
          requested: format(parseISO(note.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
        }))
      );

      const numberOfPages = roundTo.up(
        response.data.count / response.data.limit,
        0
      );

      setCount(numberOfPages);
    } catch (err) {
      toast.error('Erro ao carregar notas!');
    } finally {
      setLoader(false);
    }
  };

  const readNote = async id => {
    setLoader(true);

    try {
      await api.put(`/notes/${id}`);

      getNotes();
    } catch (err) {
      toast.error('Erro ao carregar notas!');
    }
  };

  useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Container>
      <h1>Notas</h1>

      <hr />

      {loader ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <NotesContainer>
          {notes.length > 0 ? (
            notes.map(note => (
              <Note
                key={note.id}
                unRead={note.read}
                onClick={() => readNote(note.id)}
              >
                <div>
                  <h1>{note.message}</h1>

                  <h1>{note.demand.type}</h1>
                </div>

                <div>
                  <p>{note.user.name}</p>
                  <span>{note.requested}</span>
                </div>
              </Note>
            ))
          ) : (
            <EmptyDiv>
              <h1>Nenhuma nota encontrada</h1>
            </EmptyDiv>
          )}
        </NotesContainer>
      )}

      <Pagination
        numberOfPages={count}
        activePage={page}
        changePage={changePage}
      />
    </Container>
  );
}
