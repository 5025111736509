/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Input } from '@rocketseat/unform';
import { FaFileExcel, FaFileDownload } from 'react-icons/fa';
import { AiOutlineCheck, AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { BsArrowRepeat, BsFileEarmarkArrowUp } from 'react-icons/bs';
import { MdClose, MdClear } from 'react-icons/md';
import { format, parseISO, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt';
import roundTo from 'round-to';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

import api from '~/services/api';
import { updateSearchRequest } from '~/store/modules/user/actions';

import CustomButton from '~/components/Form/Button/Custom';
import DeleteButton from '~/components/Form/Button/Delete';
import Loader from '~/components/Loader';
import Table from '~/components/Table';
import Pagination from '~/components/Pagination';
import Modal from '~/components/Modal/List';
import Chat from '~/components/Chat';

import {
  Container,
  LoaderContainer,
  DivFilter,
  DivInput,
  DivDate,
  DivButtons,
  DemandContainer,
  ContainerButtons,
  ContainerStatus,
  DivStatus,
  DemandHeader,
  DemandContent,
  DemandFooter,
  DemandResume,
  DemandFiles,
  EmptyDiv,
  FileHeader,
  File,
  ChatContainer,
  DivTask,
  ModalTask,
  HeaderModal,
  ContentModal,
  SendTask,
  InputFile,
  DivReview,
  DivValues,
  Value,
  ValuesContainer,
  ModalFooter,
  Buttons,
} from './styles';

export default function List({ match }) {
  const [demands, setDemands] = useState([]);
  const [demandDetails, setDemandDetails] = useState(null);
  const [demandRoom, setDemandRoom] = useState(
    match.params.id ? Number(match.params.id) : null
  );
  const [files, setFiles] = useState([]);
  const [sendFiles, setSendFiles] = useState([]);
  const [reviewFiles, setReviewFiles] = useState([]);
  const [excel, setExcel] = useState([]);
  const [loader, setLoader] = useState(true);
  const [clients, setClients] = useState([]);
  const [collabs, setCollabs] = useState([]);
  const [page, setPage] = useState(1);
  const [type, setType] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [initialDeadline, setInitialDeadline] = useState('');
  const [finalDeadline, setFinalDeadline] = useState('');
  const [initialRequested, setInitialRequested] = useState('');
  const [finalRequested, setFinalRequested] = useState('');
  const [term, setTerm] = useState('');
  const [count, setCount] = useState(0);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalTask, setToggleModalTask] = useState(false);
  const { profile, search } = useSelector(state => state.user);
  const [statusDemand, setStatusDemand] = useState(
    profile.type.type === 'adm' ? 'Criada' : ''
  );
  const [money, setMoney] = useState('');
  const [price, setPrice] = useState(0);
  const [values, setValues] = useState([]);
  const fileRef = useRef(null);
  const newFileRef = useRef(null);
  const dispatch = useDispatch();

  const exportExcel = () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = 'demandas';

    const ws = XLSX.utils.json_to_sheet(excel);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const getClients = async () => {
    try {
      const response = await api.get('/clients');

      setClients(
        response.data.map(client => ({
          id: client.id,
          name: client.name,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar clientes!');
    }
  };

  const changePage = num => {
    setPage(num + 1);
  };

  const getStatus = status => {
    switch (status) {
      case 'Criada': {
        return 1;
      }
      case 'Direcionada': {
        return 2;
      }
      case 'Em Progresso': {
        return 3;
      }
      case 'Em Analise': {
        return 4;
      }
      case 'Concluído': {
        return 5;
      }
      default:
        return 0;
    }
  };

  const getDemands = async () => {
    setLoader(true);

    try {
      const response = await api.get('/demands', {
        params: {
          page,
          client: selectedClient,
          type,
          initialDeadline,
          finalDeadline,
          initialRequested,
          finalRequested,
          search: term,
          status: statusDemand,
        },
      });

      setDemands(
        response.data.response.map(item => ({
          id_: item.demand.id,
          type: item.demand.type,
          deadline: format(
            addDays(parseISO(item.demand.deadline), 1),
            "dd'/'MM'/'yyyy"
          ),
          country: item.demand.country,
          processNumber: item.demand.process_number,
          client: item.client,
          user: item.demand.user.name,
          requested: format(parseISO(item.demand.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
          status: item.demand.status,
        }))
      );

      setDemandDetails(null);

      const responseExcel = await api.get('/demands/excel', {
        params: {
          page,
          client: selectedClient,
          type,
          initialDeadline,
          finalDeadline,
          initialRequested,
          finalRequested,
          search: term,
        },
      });

      setExcel(
        responseExcel.data.map(item => ({
          ID: item.demand.id,
          Tipo: item.demand.type,
          Prazo: format(parseISO(item.demand.deadline), "dd'/'MM'/'yyyy"),
          Comarca: item.demand.country,
          N_Processo: item.demand.process_number,
          Reclamante: item.demand.complainer,
          Reclamada: item.demand.claimed,
          Forum_Tribunal: item.demand.forum_tribune,
          Vara: item.demand.vara,
          Cliente: item.client,
          Usuario: item.demand.user.name,
          Solicitado: format(
            parseISO(item.demand.createdAt),
            "dd'/'MM'/'yyyy",
            {
              locale: pt,
            }
          ),
          Resumo: item.demand.resume,
          Status: item.demand.status,
          Colaborador: item.demand.collab ? item.demand.collab.name : '',
        }))
      );

      dispatch(updateSearchRequest(''));

      const numberOfPages = roundTo.up(
        response.data.count / response.data.limit,
        0
      );

      setCount(numberOfPages);
    } catch (err) {
      toast.error('Erro ao carregar demandas!');
    } finally {
      setLoader(false);
    }
  };

  const convertSize = sizeFile => {
    const size = Number(sizeFile) / 1000;
    if (size >= 1000) return `${Math.round(size / 1000, -1)} Mb`;

    return `${Math.round(size, -1)}Kb`;
  };

  const getDemandDetails = async id => {
    setDemandRoom(null);

    try {
      const response = await api.get(`/demands/${id}/details`);

      setDemandDetails({
        ...response.data.demand,
        client_name: response.data.client.name,
      });

      const responseFiles = await api.get(`/files/${id}`, {
        params: {
          type: 'attachment',
        },
      });

      setFiles(
        responseFiles.data.map(file => ({
          ...file,
          date: format(parseISO(file.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
          size: convertSize(file.size),
        }))
      );

      const responseReview = await api.get(`/files/${id}`, {
        params: {
          type: 'assignment',
        },
      });

      setReviewFiles(
        responseReview.data.map(file => ({
          ...file,
          date: format(parseISO(file.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
          size: convertSize(file.size),
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar demanda!');
    } finally {
      setDemandRoom(id);
    }
  };

  const deleteFile = async id => {
    try {
      await api.delete(`/files/${id}`);

      getDemandDetails(demandDetails.id);
      toast.success('Arquivo deletado.');
    } catch (err) {
      toast.error('Erro ao deletar demanda!');
    }
  };

  const redirectDemand = async id => {
    try {
      const response = await api.put(`/demands/${demandDetails.id}`, {
        status: 'Direcionada',
        collab_id: id,
      });

      await api.post(`/notes/${response.data.id}`);

      setToggleModal(false);
      await getDemands();
      getDemandDetails(response.data.id);
      toast.success('Demanda redirecionada ao colaborador.');
    } catch (err) {
      toast.error('Erro ao atualizar demanda!');
    }
  };

  const finishDemand = async () => {
    try {
      const response = await api.put(`/demands/${demandDetails.id}`, {
        status: 'Concluído',
      });

      await api.post(`/notes/${response.data.id}`);

      getDemandDetails(response.data.id);
      toast.success('Demanda finalizada.');
    } catch (err) {
      toast.error('Erro ao atualizar demanda!');
    }
  };

  const reviewDemand = async () => {
    try {
      const response = await api.put(`/demands/${demandDetails.id}`, {
        status: 'Em Progresso',
      });

      await api.post(`/notes/${response.data.id}`);

      reviewFiles.map(async file => {
        await api.delete(`/files/${file.id}`);
      });

      getDemands();
      getDemandDetails(response.data.id);
      toast.success('Demanda mandada para revisão.');
    } catch (err) {
      toast.error('Erro ao atualizar demanda!');
    }
  };

  const getCollabs = async () => {
    try {
      const response = await api.get(`/demands/${demandDetails.id}/collabs`);

      const data = [...response.data];
      data.sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.user.name > b.user.name ? 1 : b.user.name > a.user.name ? -1 : 0
      );
      setCollabs(
        data.map(userData => {
          return (
            <li
              key={userData.user.id}
              onClick={() => redirectDemand(userData.user.id)}
            >
              <p>{userData.user.name}</p>
              <span>{userData.user.email}</span>
            </li>
          );
        })
      );
    } catch (err) {
      toast.error('Erro ao carregar colaboradores!');
    }
  };

  const acceptRefuseDemand = async bool => {
    try {
      if (bool) {
        const response = await api.put(`/demands/${demandDetails.id}`, {
          status: 'Em Progresso',
        });

        await api.post(`/notes/${response.data.id}`);

        await getDemands();
        getDemandDetails(response.data.id);

        await api.post('/rooms', {
          demand_id: response.data.id,
          client_id: response.data.user_id,
          user_id: profile.id,
        });

        await api.post('/rooms', {
          demand_id: response.data.id,
          client_id: profile.id,
          user_id: response.data.moderator_id,
        });

        toast.success('Demanda aceita.');
      } else {
        await api.put(`/demands/${demandDetails.id}`, {
          status: 'Criada',
          collab_id: null,
        });

        await api.post(`/notes/${demandDetails.id}`);

        await api.post('/demands/collabs', {
          demand_id: demandDetails.id,
          collab_id: profile.id,
        });

        setDemandDetails(null);
        getDemands();
        toast.warn('Demanda recusada.');
      }
    } catch (err) {
      toast.error('Erro ao atualizar demanda!');
    }
  };

  const attachFile = () => {
    const currentFiles = [...fileRef.current.files];

    fileRef.current.value = null;

    setSendFiles([...sendFiles, ...currentFiles]);
  };

  const attachValue = () => {
    if (money !== '' && price > 0) {
      setValues([
        ...values,
        {
          money,
          price: Number(price),
        },
      ]);
      setMoney('');
      setPrice(0);
    }
  };

  const removeAttachment = paramFile => {
    setSendFiles(sendFiles.filter(file => file !== paramFile));
  };

  const removeValue = paramValue => {
    setValues(values.filter(value => value !== paramValue));
  };

  const sendTask = async () => {
    try {
      if (sendFiles.length > 0) {
        sendFiles.map(async file => {
          const content = new FormData();

          content.append('file', file);
          content.append('type', 'assignment');

          await api.post(`/files/${demandDetails.id}`, content);
        });

        values.map(async value => {
          const data = {
            ...value,
            demand_id: demandDetails.id,
          };

          await api.post(`/demands/money`, data);
        });

        const response = await api.put(`/demands/${demandDetails.id}`, {
          status: 'Em Analise',
        });

        await api.post(`/notes/${response.data.id}`);

        await getDemands();
        getDemandDetails(response.data.id);
        setSendFiles([]);
        setValues([]);
        toast.success('Tarefa enviada com sucesso.');
        setToggleModalTask(false);
      } else {
        toast.warn(
          'Pelo menos um arquivo deve ser anexado para finalizar a tarefa.'
        );
      }
    } catch (err) {
      toast.error('Erro ao enviar tarefa, tente novamente.');
    }
  };

  const newFile = async () => {
    try {
      const currentFiles = [...newFileRef.current.files];
      newFileRef.current.value = null;

      if (currentFiles.length > 0) {
        currentFiles.map(async file => {
          const content = new FormData();

          content.append('file', file);
          content.append('type', 'attachment');

          await api.post(`/files/${demandDetails.id}`, content);
        });

        getDemandDetails(demandDetails.id);

        toast.success('Arquivo enviado com sucesso.');
      } else {
        toast.warn('Pelo menos um arquivo deve ser anexado.');
      }
    } catch (err) {
      toast.error('Erro ao enviar tarefa, tente novamente.');
    }
  };

  useEffect(() => {
    getDemands();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, page]);

  useEffect(() => {
    if (demandRoom && !demandDetails) {
      getDemandDetails(demandRoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [demandRoom]);

  useEffect(() => {
    async function readNotes() {
      if (demandDetails) await api.put(`/notes/${demandDetails.id}/demands`);
    }

    readNotes();
  }, [demandDetails]);

  useEffect(() => {
    if (search !== '') setTerm(search);
  }, [search]);

  useEffect(() => {
    if (profile.type.type === 'adm') getClients();
  }, [profile]);

  useEffect(() => {
    if (toggleModal && profile.type.type === 'adm') getCollabs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleModal]);

  const header = [
    'ID',
    'Tipo de Demanda',
    'Prazo',
    'Comarca',
    'Número do Processo',
    'Cliente',
    'Usuário',
    'Solicitado',
    'Status',
  ];

  return (
    <>
      <Modal
        show={toggleModal}
        setState={setToggleModal}
        title="Lista de colaboradores"
        data={collabs}
      />

      {demandDetails && (
        <ModalTask show={toggleModalTask}>
          <HeaderModal>
            <h2>Finalizar Tarefa</h2>
            <MdClear
              color="#fff"
              size={20}
              onClick={() => setToggleModalTask(false)}
            />
          </HeaderModal>

          <ContentModal>
            <span>Processo / Caso</span>

            <h1>
              {demandDetails.process_number} {demandDetails.user.name} /{' '}
              {demandDetails.client_name} / {demandDetails.type}
            </h1>

            <DivReview>
              <DemandFiles>
                {sendFiles.length > 0 && (
                  <FileHeader>
                    <span>Arquivo</span>
                    <span>Tamanho</span>
                    <span>Data</span>
                    <span>Criado por</span>
                    <span> </span>
                  </FileHeader>
                )}

                {sendFiles.length > 0 ? (
                  sendFiles.map(file => (
                    <File key={file.id}>
                      <div>
                        <Tooltip title={file.name} arrow>
                          <a>
                            <FaFileDownload color="#fff" size={20} />
                          </a>
                        </Tooltip>
                      </div>
                      <span>{convertSize(file.size)}</span>
                      <span>
                        {format(new Date(), "dd'/'MM'/'yyyy", {
                          locale: pt,
                        })}
                      </span>
                      <span>{profile.name}</span>
                      <div>
                        <DeleteButton func={() => removeAttachment(file)} />
                      </div>
                    </File>
                  ))
                ) : (
                  <EmptyDiv>
                    <h1>Nenhum arquivo encontrado.</h1>
                  </EmptyDiv>
                )}
              </DemandFiles>

              <InputFile>
                <input
                  ref={fileRef}
                  name="file"
                  type="file"
                  id="file"
                  multiple
                  placeholder="Anexos"
                  onChange={e => attachFile(e.target.value)}
                />

                <label htmlFor="file" className="input-button">
                  <BsFileEarmarkArrowUp color="#fff" size={22} />
                  Anexos
                </label>
              </InputFile>
            </DivReview>

            <hr />

            <span>Inserir Valores</span>

            <DivValues>
              <div>
                <input
                  type="text"
                  placeholder="Digite uma verba"
                  value={money}
                  onChange={e => setMoney(e.target.value)}
                />
                <input
                  type="number"
                  value={price}
                  onChange={e => setPrice(e.target.value)}
                />
                <button type="button" onClick={() => attachValue()}>
                  <AiOutlinePlus color="#323232" size={20} />
                </button>
              </div>

              <ValuesContainer>
                {values.length > 0 ? (
                  values.map(value => (
                    <Value key={String(value.price)}>
                      <span>{value.money}</span>
                      <span>{value.price}</span>
                      <button type="button" onClick={() => removeValue(value)}>
                        <MdClose color="#fff" size={20} />
                      </button>
                    </Value>
                  ))
                ) : (
                  <h1>Nenhum valor adicionado.</h1>
                )}
              </ValuesContainer>
            </DivValues>
          </ContentModal>

          <ModalFooter>
            <button onClick={() => sendTask()}>Enviar</button>
            <button onClick={() => setToggleModalTask(false)}>Fechar</button>
          </ModalFooter>
        </ModalTask>
      )}

      <Container>
        <Form>
          <DivFilter>
            <DivInput>
              {profile.type.type === 'adm' && (
                <div>
                  <span>Cliente:</span>
                  <select
                    name="client"
                    defaultValue={selectedClient}
                    onChange={e => setSelectedClient(e.target.value)}
                  >
                    <option value="">Selecione</option>
                    {clients.map(client => (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div>
                <span>Tipo de Demanda:</span>
                <select
                  name="type"
                  defaultValue={type}
                  onChange={e => setType(e.target.value)}
                >
                  <option value="">Todas</option>
                  <option value="Liquidação da Inicial">
                    Liquidação da Inicial
                  </option>
                  <option value="Liquidação da Sentença">
                    Liquidação da Sentença
                  </option>
                  <option value="Liquidação do Acórdão">
                    Liquidação do Acórdão
                  </option>
                  <option value="Liquidação (Execução)">
                    Liquidação (Execução)
                  </option>
                  <option value="Impugnação">Impugnação</option>
                  <option value="Imp. aos Esclarecimentos">
                    Imp. aos Esclarecimentos
                  </option>
                  <option value="Embargos à Execução">
                    Embargos à Execução
                  </option>
                  <option value="Man. ao Laudo Pericial">
                    Man. ao Laudo Pericial
                  </option>
                  <option value="Agravo de Petição">Agravo de Petição</option>
                  <option value="Elaboração de Quesitos">
                    Elaboração de Quesitos
                  </option>
                  <option value="Discriminação de Verbas">
                    Discriminação de Verbas
                  </option>
                  <option value="Atualização">Atualização</option>
                </select>
              </div>

              <div>
                <span>Status:</span>
                <select
                  name="status"
                  defaultValue={statusDemand}
                  onChange={e => setStatusDemand(e.target.value)}
                >
                  <option value="">Todas</option>
                  <option value="Criada">Demanda Criada</option>
                  <option value="Direcionada">Direcionada ao Calculista</option>
                  <option value="Em Progresso">Em Progresso</option>
                  <option value="Em Analise">
                    Em Analise (Controle de Qualidade)
                  </option>
                  <option value="Concluído">Concluído</option>
                </select>
              </div>
            </DivInput>

            <DivDate>
              <div>
                <span>Prazo de:</span>
                <Input
                  name="initial_deadline"
                  type="date"
                  placeholder="Data Inicial"
                  value={initialDeadline}
                  onChange={e => setInitialDeadline(e.target.value)}
                />
              </div>

              <div>
                <span>até:</span>
                <Input
                  name="final_deadline"
                  type="date"
                  placeholder="Data Final"
                  value={finalDeadline}
                  onChange={e => setFinalDeadline(e.target.value)}
                />
              </div>

              <div>
                <span>Solicitado de:</span>
                <Input
                  name="initial_create"
                  type="date"
                  placeholder="Data Inicial"
                  value={initialRequested}
                  onChange={e => setInitialRequested(e.target.value)}
                />
              </div>

              <div>
                <span>até:</span>
                <Input
                  name="final_create"
                  type="date"
                  placeholder="Data Final"
                  value={finalRequested}
                  onChange={e => setFinalRequested(e.target.value)}
                />
              </div>
            </DivDate>
          </DivFilter>

          <DivButtons>
            <CustomButton func={exportExcel} text="EXPORTAR PARA EXCEL">
              <FaFileExcel color="#fff" size={18} />
            </CustomButton>
            <button type="button" onClick={getDemands}>
              FILTRAR
            </button>
          </DivButtons>
        </Form>

        {loader ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <Table
              title=""
              header={header}
              data={demands}
              func={getDemandDetails}
              size="1200px"
            />
            <Pagination
              numberOfPages={count}
              activePage={page}
              changePage={changePage}
            />
          </>
        )}

        {demandDetails && (
          <>
            <hr />

            {profile.type.type === 'adm' && (
              <ContainerButtons>
                {getStatus(demandDetails.status) === 4 && (
                  <DivReview>
                    <DemandFiles>
                      {reviewFiles.length > 0 && (
                        <FileHeader>
                          <span>Arquivo</span>
                          <span>Tamanho</span>
                          <span>Data</span>
                          <span>Criado por</span>
                        </FileHeader>
                      )}

                      {reviewFiles.length > 0 ? (
                        reviewFiles.map(file => (
                          <File key={file.id}>
                            <div>
                              <Tooltip title={file.name} arrow>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaFileDownload color="#fff" size={20} />
                                </a>
                              </Tooltip>
                            </div>
                            <span>{file.size}</span>
                            <span>{file.date}</span>
                            <span>{file.user.name}</span>
                          </File>
                        ))
                      ) : (
                        <EmptyDiv>
                          <h1>Nenhum arquivo encontrado.</h1>
                        </EmptyDiv>
                      )}
                    </DemandFiles>

                    <Buttons>
                      <button type="button" onClick={reviewDemand}>
                        <MdClose color="#fff" size={18} />
                        REVISAR
                      </button>
                      <button type="button" onClick={finishDemand}>
                        <AiOutlineCheck color="#fff" size={18} />
                        FINALIZAR
                      </button>
                    </Buttons>
                  </DivReview>
                )}

                {getStatus(demandDetails.status) === 5 && (
                  <DivReview>
                    <DemandFiles>
                      {reviewFiles.length > 0 && (
                        <FileHeader>
                          <span>Arquivo</span>
                          <span>Tamanho</span>
                          <span>Data</span>
                          <span>Criado por</span>
                        </FileHeader>
                      )}

                      {reviewFiles.length > 0 ? (
                        reviewFiles.map(file => (
                          <File key={file.id}>
                            <div>
                              <Tooltip title={file.name} arrow>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaFileDownload color="#fff" size={20} />
                                </a>
                              </Tooltip>
                            </div>
                            <span>{file.size}</span>
                            <span>{file.date}</span>
                            <span>{file.user.name}</span>
                          </File>
                        ))
                      ) : (
                        <EmptyDiv>
                          <h1>Nenhum arquivo encontrado.</h1>
                        </EmptyDiv>
                      )}
                    </DemandFiles>
                  </DivReview>
                )}

                {getStatus(demandDetails.status) === 1 && (
                  <button type="button" onClick={() => setToggleModal(true)}>
                    <BsArrowRepeat color="#fff" size={18} />
                    ENVIAR DEMANDA PARA UM PROFISSIONAL DA MINHA EQUIPE
                  </button>
                )}
              </ContainerButtons>
            )}

            {profile.type.type === 'collab' && (
              <ContainerButtons>
                {getStatus(demandDetails.status) === 2 && (
                  <>
                    <button
                      type="button"
                      onClick={() => acceptRefuseDemand(true)}
                    >
                      <AiOutlineCheck color="#fff" size={18} />
                      ACEITAR DEMANDA
                    </button>

                    <button
                      type="button"
                      onClick={() => acceptRefuseDemand(false)}
                    >
                      <AiOutlineClose color="#fff" size={18} />
                      RECUSAR DEMANDA
                    </button>
                  </>
                )}

                {getStatus(demandDetails.status) === 3 && (
                  <DivTask>
                    <SendTask>
                      <button
                        type="button"
                        onClick={() => setToggleModalTask(true)}
                      >
                        <BsFileEarmarkArrowUp color="#fff" size={22} />
                        Finalizar tarefa
                      </button>
                    </SendTask>
                  </DivTask>
                )}

                {getStatus(demandDetails.status) === 4 && (
                  <DivReview>
                    <DemandFiles>
                      {reviewFiles.length > 0 && (
                        <FileHeader>
                          <span>Arquivo</span>
                          <span>Tamanho</span>
                          <span>Data</span>
                          <span>Criado por</span>
                        </FileHeader>
                      )}

                      {reviewFiles.length > 0 ? (
                        reviewFiles.map(file => (
                          <File key={file.id}>
                            <div>
                              <Tooltip title={file.name} arrow>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaFileDownload color="#fff" size={20} />
                                </a>
                              </Tooltip>
                            </div>
                            <span>{file.size}</span>
                            <span>{file.date}</span>
                            <span>{file.user.name}</span>
                          </File>
                        ))
                      ) : (
                        <EmptyDiv>
                          <h1>Nenhum arquivo encontrado.</h1>
                        </EmptyDiv>
                      )}
                    </DemandFiles>
                  </DivReview>
                )}

                {getStatus(demandDetails.status) === 5 && (
                  <DivReview>
                    <DemandFiles>
                      {reviewFiles.length > 0 && (
                        <FileHeader>
                          <span>Arquivo</span>
                          <span>Tamanho</span>
                          <span>Data</span>
                          <span>Criado por</span>
                        </FileHeader>
                      )}

                      {reviewFiles.length > 0 ? (
                        reviewFiles.map(file => (
                          <File key={file.id}>
                            <div>
                              <Tooltip title={file.name} arrow>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaFileDownload color="#fff" size={20} />
                                </a>
                              </Tooltip>
                            </div>
                            <span>{file.size}</span>
                            <span>{file.date}</span>
                            <span>{file.user.name}</span>
                          </File>
                        ))
                      ) : (
                        <EmptyDiv>
                          <h1>Nenhum arquivo encontrado.</h1>
                        </EmptyDiv>
                      )}
                    </DemandFiles>
                  </DivReview>
                )}
              </ContainerButtons>
            )}

            {profile.type.type === 'client' && (
              <ContainerButtons>
                {getStatus(demandDetails.status) === 5 && (
                  <DivReview>
                    <DemandFiles>
                      {reviewFiles.length > 0 && (
                        <FileHeader>
                          <span>Arquivo</span>
                          <span>Tamanho</span>
                          <span>Data</span>
                          <span>Criado por</span>
                        </FileHeader>
                      )}

                      {reviewFiles.length > 0 ? (
                        reviewFiles.map(file => (
                          <File key={file.id}>
                            <div>
                              <Tooltip title={file.name} arrow>
                                <a
                                  href={file.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FaFileDownload color="#fff" size={20} />
                                </a>
                              </Tooltip>
                            </div>
                            <span>{file.size}</span>
                            <span>{file.date}</span>
                            <span>{file.user.name}</span>
                          </File>
                        ))
                      ) : (
                        <EmptyDiv>
                          <h1>Nenhum arquivo encontrado.</h1>
                        </EmptyDiv>
                      )}
                    </DemandFiles>
                  </DivReview>
                )}
              </ContainerButtons>
            )}

            <ContainerStatus>
              <section>
                <DivStatus status={getStatus(demandDetails.status) >= 1}>
                  <div>
                    <span />
                  </div>
                  <p>Demanda Criada</p>
                </DivStatus>

                <DivStatus status={getStatus(demandDetails.status) >= 2}>
                  <hr />
                  <div>
                    <span />
                  </div>
                  <p>Direcionada ao Calculista</p>
                </DivStatus>

                <DivStatus status={getStatus(demandDetails.status) >= 3}>
                  <hr />
                  <div>
                    <span />
                  </div>
                  <p>Em Progresso</p>
                </DivStatus>

                <DivStatus status={getStatus(demandDetails.status) >= 4}>
                  <hr />
                  <div>
                    <span />
                  </div>
                  <p>
                    Em Analise
                    <br /> (Controle de Qualidade)
                  </p>
                </DivStatus>

                <DivStatus status={getStatus(demandDetails.status) === 5}>
                  <hr />
                  <div>
                    <span />
                  </div>
                  <p>Concluído</p>
                </DivStatus>
              </section>
            </ContainerStatus>

            <DemandContainer>
              <DemandHeader>
                <h1>
                  {demandDetails.type} para{' '}
                  {format(
                    addDays(parseISO(demandDetails.deadline), 1),
                    "dd'/'MM'/'Y'"
                  )}
                </h1>

                <span>Comarca: {demandDetails.country}</span>
                <div>
                  <span>Solicitante: {demandDetails.user.name}</span>
                </div>
                <span>Reclamada: {demandDetails.claimed}</span>
              </DemandHeader>
              <hr />
              <DemandContent>
                <div>
                  <span>ID:</span>
                  <p>{demandDetails.id}</p>
                </div>
                <div>
                  <span>Número do Processo:</span>
                  <p>{demandDetails.process_number}</p>
                </div>
                <div>
                  <span>Cliente:</span>
                  <p>{demandDetails.user.name}</p>
                </div>
                <div>
                  <span>Reclamante:</span>
                  <p>{demandDetails.complainer}</p>
                </div>
                <div>
                  <span>Comarca:</span>
                  <p>{demandDetails.country}</p>
                </div>
                <div>
                  <span>Foro/Tribunal:</span>
                  <p>{demandDetails.forum_tribune}</p>
                </div>
                <div>
                  <span>Vara:</span>
                  <p>{demandDetails.vara}</p>
                </div>
              </DemandContent>

              <hr />

              <DemandFooter>
                <div>
                  <span>Solicitado por:</span>
                  <p>
                    {demandDetails.user.name}{' '}
                    {format(parseISO(demandDetails.createdAt), "dd'/'MM'/'Y'", {
                      locale: pt,
                    })}
                  </p>
                </div>
              </DemandFooter>
            </DemandContainer>

            <DemandResume>
              <span>Resumo:</span>
              <p>{demandDetails.resume}</p>
            </DemandResume>

            <DemandFiles>
              {files.length > 0 && (
                <FileHeader>
                  <span>Arquivo</span>
                  <span>Tamanho</span>
                  <span>Data</span>
                  <span>Criado por</span>
                  <span> </span>
                </FileHeader>
              )}

              {files.length > 0 ? (
                files.map(file => (
                  <File key={file.id}>
                    <div>
                      <Tooltip title={file.name} arrow>
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFileDownload color="#fff" size={20} />
                        </a>
                      </Tooltip>
                    </div>
                    <span>{file.size}</span>
                    <span>{file.date}</span>
                    <span>{file.user.name}</span>
                    <div>
                      <DeleteButton func={() => deleteFile(file.id)} />
                    </div>
                  </File>
                ))
              ) : (
                <EmptyDiv>
                  <h1>Nenhum arquivo encontrado.</h1>
                </EmptyDiv>
              )}

              <SendTask>
                <InputFile>
                  <input
                    ref={newFileRef}
                    name="file"
                    type="file"
                    id="file"
                    multiple
                    placeholder="Anexos"
                    onChange={e => newFile(e.target.value)}
                  />

                  <label htmlFor="file">
                    <BsFileEarmarkArrowUp color="#fff" size={22} />
                    Anexar
                  </label>
                </InputFile>
              </SendTask>
            </DemandFiles>

            {demandRoom && (
              <ChatContainer>
                <Chat demand_id={demandRoom} />
              </ChatContainer>
            )}
          </>
        )}
      </Container>
    </>
  );
}

List.defaultProps = {
  match: {},
};

List.propTypes = {
  match: PropTypes.object,
};
