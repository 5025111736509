import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const CreateModal = styled.div`
  ${props =>
    props.show
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  flex-direction: column;
  position: absolute;
  width: 400px;
  background: #323232;
  border-radius: 8px;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;

  @media (max-width: 450px) {
    width: 100%;
    max-width: calc(100vw - 60px);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: #b89b29;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-left: 1px solid #b89b29;
  border-right: 1px solid #b89b29;

  h2 {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  padding: 20px 0;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      min-height: 50px;
      padding: 10px 20px;
      cursor: pointer;
      transition: background 0.3s;
      position: relative;

      &:hover {
        background: ${lighten(0.1, '#323232')};
      }

      & + li {
        margin-top: 27px;

        &::before {
          content: '';
          width: 94%;
          height: 3px;
          background: #fff;
          top: -15px;
          left: 3%;
          position: absolute;
        }
      }

      p {
        color: #fff;
        font-weight: bold;
        font-size: 16px;
      }

      span {
        margin-top: 13px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
`;
