import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  h1 {
    color: ${lighten(0.3, '#000000')};
    font-size: 1.7em;
    font-weight: 600;
    margin-left: 10px;
  }
`;

export const CreateTable = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-collapse: collapse;
  font-size: 1em;
  margin-top: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  ${props =>
    props.size &&
    css`
      min-width: ${props.size};
    `}

  thead {
    width: 100%;

    tr {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      background: #000000;

      th {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: bold;
      }
    }
  }

  tbody {
    width: 100%;

    tr {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;
      border-bottom: 1px solid #dddddd;

      td {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div {
        width: 100%;
        min-height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 17px;
          font-weight: 500;
          color: #000000;
        }
      }
    }

    tr:nth-of-type(even) {
      background: #f3f3f3;
    }

    tr:last-of-type {
      border-bottom: 2px solid #b89b29;
    }
  }

  th,
  td {
    padding: 12px 0;
  }
`;
