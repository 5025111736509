import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import api from '~/services/api';
import history from '~/services/history';

import { Container } from './styles';
import ReturnButton from '~/components/Form/Button/Return';

const schema = Yup.object().shape({
  name: Yup.string(),
});

export default function Client({ match }) {
  const [user, setUser] = useState({});
  const [id] = useState(match.params.id ? match.params.id : false);

  useEffect(() => {
    async function loadUser() {
      if (id) {
        const response = await api.get(`/clients/${id}/details`);

        setUser(response.data);
      }
    }

    loadUser();
  }, [id]);

  async function handleSubmit(data) {
    try {
      await api.put(`/clients/${id}`, {
        ...data,
      });

      toast.success('Cliente atualizado com sucesso.');
      history.push('/members');
    } catch (err) {
      console.tron.log(err);
      toast.error('Erro ao atualizar usuário, confira a data dele!');
    }
  }

  return (
    <Container>
      <ReturnButton url="/members" text="Voltar" />

      <hr />

      <div>
        <Form schema={schema} initialData={user} onSubmit={handleSubmit}>
          <Input name="name" placeholder="Nome" />
          <button type="submit">Atualizar usuário</button>
        </Form>
      </div>
    </Container>
  );
}

Client.propTypes = {
  match: PropTypes.object.isRequired,
};
