import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'chart-bar horizontal-bar'
    'table table';
  grid-gap: 0px 20px;
  width: 100%;

  &:nth-child(1) {
    grid-area: chart-bar;
  }

  &:nth-child(2) {
    grid-area: horizontal-bar;
  }

  @media (max-width: 1400px) {
    & {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;

export const ChartBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 1400px) {
    margin-bottom: 30px;
  }
`;

export const DateFilterProcess = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div:last-of-type {
    display: grid;
    grid-template-columns: 3fr 1fr 3fr;
    grid-gap: 15px;
    width: 100%;
    margin-top: 15px;
  }

  input {
    background: #fff;
    border: 1px solid ${darken(0.07, '#eee')};
    border-radius: 4px;
    height: 44px;
    width: 100%;
    padding: 0 15px;
    color: #333;
    margin: 0 0 10px;

    &:focus {
      border: 1px solid #000000;
    }

    &::placeholder {
      color: #999;
    }
  }

  input[type='datetime-local'],
  input[type='date'] {
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  span {
    text-align: center;
    height: 50%;
    align-self: center;
    color: #000000;
    font-size: 17px;
    font-weight: bold;
  }
`;

export const CourtContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-area: table;

  table {
    margin-bottom: 20px;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 350px;
`;
