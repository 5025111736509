import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Wrapper = styled.div`
  background: ${lighten(0.2, '#a0a0a0')};
  min-height: 100vh;
  overflow-y: hidden !important;

  @media (min-width: 901px) {
    height: 100%;
    overflow-y: auto;
  }
`;

export const Body = styled.div`
  height: 90%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 30px;
  overflow-y: auto;

  @media (max-width: 900px) {
    overflow-y: hidden;
  }
`;

export const Main = styled.div`
  display: flex;
  background: #fff;
  border-radius: 4px;
  padding: 30px;
  width: 100%;
  min-height: 300px;

  hr {
    border: 0;
    width: 95%;
    height: 1px;
    background: #999;
    margin: 20px 2.5% !important;
  }

  form {
    display: flex;
    flex-direction: column;

    input {
      background: #fff;
      border: 1px solid ${darken(0.07, '#eee')};
      border-radius: 4px;
      height: 44px;
      width: 100%;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &:focus {
        border: 1px solid #000000;
      }

      &::placeholder {
        color: #999;
      }
    }

    input[type='datetime-local'],
    input[type='date'] {
      &::-webkit-clear-button,
      &::-webkit-inner-spin-button {
        display: none;
      }
    }

    span {
      color: #222222;
      margin: 0 auto 10px;
      font-weight: bold;
    }

    select {
      background: #fff;
      border: 1px solid ${darken(0.07, '#eee')};
      border-radius: 4px;
      height: 44px;
      width: 100%;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &:focus {
        border: 1px solid #000000;
      }

      &::placeholder {
        color: #999;
      }

      option {
        zoom: 1.3;
      }
    }

    textarea {
      text-align: top;
      padding: 15px !important;
      background: #fff;
      border-radius: 4px;
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: 150px;
      padding: 0 15px;
      color: #333;
      margin: 0 0 10px;

      &:focus {
        border: 1px solid #2e75b5;
      }

      &::placeholder {
        color: #999;
      }
    }
  }

  hr {
    border: 0;
    height: 1px;
    background: #999;
    margin: 10px 0 20px;
  }
`;
