import React from 'react';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';

import { CreateModal, Header, Content, Footer } from './styles';

export default function Modal({ show, setState, func }) {
  return (
    <CreateModal show={show}>
      <Header>
        <h2>Excluir usuário</h2>
        <MdClear color="#fff" size={20} onClick={() => setState(false)} />
      </Header>

      <Content>
        <p>Realmente deseja excluir esse usuário?</p>
        <div />
      </Content>

      <Footer>
        <button onClick={() => func()}>Sim</button>
        <button onClick={() => setState(false)}>Não</button>
      </Footer>
    </CreateModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  func: PropTypes.func.isRequired,
};
