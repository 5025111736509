import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

export const Rooms = styled.div`
  display: flex;
  max-width: 300px;
  min-width: 300px;
  width: 100%;
  height: 450px;
  max-height: 450px;
  border-right: 1px solid ${lighten(0.6, '#333')};
  padding: 10px 6px 0;
  position: relative;
  overflow-y: scroll;

  @media (max-width: 1000px) {
    ${props =>
      props.active
        ? css`
            display: none;
          `
        : css`
            max-width: 100%;
          `}
  }

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Room = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 63px;
  padding: 3px 4px;
  position: relative;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: ${darken(0.04, '#f2f2f2')};
  }

  &:last-child {
    margin-bottom: 10px;
  }

  & + li {
    margin-top: 27px;

    &::before {
      content: '';
      width: 94%;
      height: 3px;
      background: #b89b29;
      top: -15px;
      left: 3%;
      position: absolute;
    }
  }

  ${props =>
    props.unRead &&
    css`
      background: linear-gradient(
        to right,
        ${lighten(0.3, '#228b22')},
        ${lighten(0.7, '#228b22')}
      ) !important;

      &::after {
        content: '';
        width: 6px;
        height: 100%;
        background: ${lighten(0.2, '#228b22')};
        top: 0;
        left: -6px;
        position: absolute;
      }

      div {
        p {
          color: #fff !important;
        }
      }
    `}

  ${props =>
    props.active &&
    css`
      background: ${darken(0.04, '#f2f2f2')} !important;
    `}

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    p {
      color: #000000;
      font-weight: bold;
      font-size: 16px;
    }

    span {
      color: #999;
    }
  }

  p {
    font-weight: 500;
    font-size: 15px;
    text-align: justify;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 300px);
  min-width: 500px;
  height: 450px;

  @media (max-width: 1000px) {
    ${props =>
      props.active
        ? css`
            max-width: 100%;
          `
        : css`
            display: none;
          `}
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 50px;
    padding: 10px 20px;
    border-bottom: 1px solid ${lighten(0.6, '#333')};
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.4);

    button {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;

      svg {
        cursor: pointer;
      }
    }

    h1 {
      font-weight: 500;
      color: #000000;
      font-size: 18px;
    }

    a {
      color: #b89b29;
      text-decoration: none;
      font-weight: 500;
      font-size: 18px;
      margin-left: 5px;
    }
  }
`;

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const Messages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column-reverse;
    max-height: 330px;
    overflow-y: auto;
    width: 100%;
    padding: 10px 20px;

    &:first-child {
      margin-top: 10px;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background: #b89b29;
      border-radius: 10px;
      top: 50px;
    }
  }
`;

export const Message = styled.li`
  display: flex;
  flex-direction: column;
  align-self: ${props => (props.you ? 'flex-end' : 'flex-start')};
  width: auto;
  min-width: 150px;
  max-width: 400px;
  min-height: 66px;
  padding: 13px 20px;
  background: ${props => (props.you ? '#dcdcdc' : '#323232')};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${props => (props.you ? '10px' : '0')};
  border-bottom-right-radius: ${props => (props.you ? '0' : '10px')};

  li + & {
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: ${props => (props.you ? '#323232' : '#fff')};
  }

  span {
    font-size: 13px;
    font-weight: 500;
    color: #999;
    margin-top: 5px;
  }
`;

export const Send = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;

  span {
    display: block;
    width: calc(100% - 50px);
    background: #323232;
    color: #fff;
    border: 0;
    border-radius: 15px;
    padding: 10px 13px;
    min-height: 30px;
    max-height: 150px;
    line-height: 20px;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 15px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    &::-webkit-scrollbar-thumb {
      background: #fff;
      border-radius: 10px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border: 0;
    background: none;
    padding-bottom: 10px;

    svg {
      transform: rotate(-35deg);
      transition: color 0.2s;

      &:hover {
        color: #b89b29 !important;
      }
    }
  }
`;
