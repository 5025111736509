import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';

export const EditButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 40px;
  height: 40px;
  background: ${lighten(0.3, '#000000')};
  border: 0;
  border-radius: 4px;
  margin: 0 !important;

  &:hover {
    background: ${lighten(0.2, '#000000')};
  }
`;
