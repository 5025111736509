import React from 'react';
import PropTypes from 'prop-types';
import { MdClear } from 'react-icons/md';

import { CreateModal, Header, Content } from './styles';

export default function Modal({ show, setState, title, data }) {
  return (
    <CreateModal show={show}>
      <Header>
        <h2>{title}</h2>
        <MdClear color="#fff" size={20} onClick={() => setState(false)} />
      </Header>

      <Content>
        <ul>
          {data.map(item => {
            return item;
          })}
        </ul>
      </Content>
    </CreateModal>
  );
}

Modal.propTypes = {
  show: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};
