import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  form {
    width: 100%;
    margin-bottom: 20px;
  }

  table tbody tr {
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
      z-index: 1;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 350px;
`;

export const DivFilter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin: 10px 0 10px 5px;
    }
  }
`;

export const DivDate = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 90px 90px;
  grid-gap: 0 20px;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
      margin: 10px 0 10px 5px;
    }
  }
`;

export const DivButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  button:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0 15px;
    height: 40px;
    background: ${lighten(0.3, '#000000')};
    border: 0;
    border-radius: 4px;

    @media (max-width: 450px) {
      margin-top: 20px;
    }

    &:hover {
      background: ${lighten(0.2, '#000000')};
    }
  }
`;

export const DemandContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #323232;
  border-radius: 8px;
  padding: 20px 40px;

  & > hr {
    width: 100% !important;
    height: 2px;
    margin: 20px 0 !important;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    flex-direction: column;

    & > button:last-of-type {
      margin: 20px 0 0 0 !important;
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 15px;
    min-height: 40px;
    background: ${lighten(0.3, '#000000')};
    border: 0;
    border-radius: 20px;

    & + button {
      margin-left: 20px;
    }

    svg {
      margin-right: 10px;
      min-width: 18px !important;
      min-height: 18px !important;
    }

    &:hover {
      background: ${lighten(0.2, '#000000')};
    }
  }
`;

export const ContainerStatus = styled.div`
  display: flex;
  align-self: center;
  width: 100%;
  max-width: 800px;
  height: 150px;
  padding: 20px 0;
  overflow-x: auto;

  section {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    min-width: 600px;
  }
`;

export const DivStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: ${props => (props.status ? '#323232' : '#e3e3e3')};
    border-radius: 50%;
    z-index: 1;

    span {
      background: ${props => (props.status ? '#b89b29' : '#e3e3e3')};
      height: 10px;
      width: 10px;
      border-radius: 50%;
    }
  }

  hr {
    background: ${props => (props.status ? '#323232' : '#e3e3e3')};
    width: 100%;
    min-width: 120px;
    height: 10px;
    z-index: 0;
    top: 10px;
    right: 97%;
    margin: 0 !important;
    position: absolute;

    @media (min-width: 1100px) {
      min-width: 140px;
    }
  }

  p {
    position: absolute;
    top: 50px;
    width: calc(100% * 4);
    text-align: center;
    font-family: Archivo;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: #4c4c4c;
  }
`;

export const DemandHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  h1 {
    display: block;
    font-size: 30px;
    font-weight: 500;
    line-height: 40px;
    color: #323232;
    margin-bottom: 20px;
    font-family: Poppins;

    @media (max-width: 550px) {
      font-size: 25px;
      line-height: 30px;
    }

    @media (max-width: 450px) {
      font-size: 20px;
      line-height: 24px;
    }
  }

  span {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #4c4c4c;

    @media (max-width: 450px) {
      font-size: 15px;
    }
  }

  div {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    margin: 10px 0;
  }
`;

export const DemandContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 100%;
  height: 230px;

  @media (max-width: 680px) {
    height: auto;
  }

  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;

    @media (max-width: 680px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & + div {
        margin-top: 10px;
      }
    }

    span {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      color: #4c4c4c;
      width: 220px;

      @media (max-width: 680px) {
        width: 100%;
        margin-bottom: 3px;
      }

      @media (max-width: 550px) {
        font-size: 16px;
      }
    }

    p {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      color: #4c4c4c;

      @media (max-width: 550px) {
        font-size: 16px;
      }
    }
  }
`;

export const DemandFooter = styled.div`
  display: flex;
  width: 100%;

  div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;

    @media (max-width: 550px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    span {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      color: #4c4c4c;
      margin-right: 15px;

      @media (max-width: 550px) {
        margin: 0 0 5px 0;
        font-size: 16px;
      }
    }

    p {
      font-family: Archivo;
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      color: #4c4c4c;

      @media (max-width: 550px) {
        font-size: 16px;
      }
    }
  }
`;

export const DemandResume = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 150px;
  margin-top: 20px;
  padding: 10px 15px;
  border: 1px solid #323232;
  border-radius: 8px;

  span {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #4c4c4c;
    margin-bottom: 10px;
  }

  p {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: #4c4c4c;
    text-align: justify;
    text-indent: 15px;
    width: 100%;
  }
`;

export const DemandFiles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 15px;
  min-height: 162px;
  border: 1px solid #323232;
  background: #fff;
  border-radius: 8px;
  overflow-x: auto;
`;

export const EmptyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 162px;
  padding: 0 10px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #4c4c4c;
    font-family: 'Roboto', sans-serif;
  }
`;

export const FileHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  min-width: 673px;
  padding: 20px 40px;
  position: relative;

  &::after {
    content: '';
    width: 90%;
    height: 3px;
    border-radius: 3px;
    background: #323232;
    position: absolute;
    top: 100%;
    left: 5%;
  }

  span {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #4c4c4c;
    text-align: center;
  }
`;

export const File = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  min-width: 673px;
  padding: 20px 40px;
  position: relative;

  div + &::before {
    content: '';
    width: 90%;
    height: 3px;
    border-radius: 3px;
    background: #323232;
    position: absolute;
    top: 0;
    left: 5%;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 15px;
      height: 40px;
      background: ${lighten(0.3, '#000000')};
      border-radius: 4px;

      &:hover {
        background: ${lighten(0.2, '#000000')};
      }
    }
  }

  span {
    font-family: Archivo;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #4c4c4c;
    text-align: center;
    margin: auto;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  border: 1px solid #323232;
  margin-top: 20px;
  z-index: 0;
  border-radius: 4px;
`;

export const DivTask = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  div:last-of-type {
    margin-top: 20px;
  }
`;

export const ModalTask = styled.div`
  ${props =>
    props.show
      ? css`
          display: flex;
        `
      : css`
          display: none;
        `}
  flex-direction: column;
  position: absolute;
  width: 700px;
  background: #fff;
  border-radius: 8px;
  padding-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  border-left: 1px solid #323232;
  border-right: 1px solid #323232;
  border-bottom: 1px solid #323232;

  @media (max-width: 450px) {
    width: 100%;
    max-width: calc(100vw - 60px);
  }
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  background: #323232;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h2 {
    color: #fff;
    font-size: 17px;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
  }
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  background: #fff;

  & > span {
    color: #323232;
    font-size: 17px;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 15px;
  }

  & > h1 {
    width: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    color: #000000;
    background: #fff;
    border: 1px solid #323232;
    border-radius: 4px;
    padding: 5px;
    font-size: 21px;
    font-family: Archivo, sans-serif;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const SendTask = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;

  @media (max-width: 500px) {
    flex-direction: column;

    & > button:last-of-type {
      margin-top: 20px;
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 45px;
    cursor: pointer;
    border-radius: 8px;
    border: none;
    background: #323232;
    color: #fff;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;

    svg {
      margin-right: 7px;
    }
  }
`;

export const InputFile = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;

  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 45px;
    cursor: pointer;
    border-radius: 8px;
    background: #323232;
    color: #fff;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;

    svg {
      margin-right: 7px;
    }
  }

  label.input-button {
    margin-top: 20px;
  }

  input[type='file'] {
    display: none;
  }
`;

export const DivReview = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DivValues = styled.div`
  display: flex;
  flex-direction: column;

  div:first-of-type {
    display: grid;
    grid-template-columns: 3fr 2fr 1fr;
    grid-gap: 15px 20px;
    align-items: center;

    input {
      background: #fff;
      border: 1px solid ${darken(0.07, '#eee')};
      border-radius: 4px;
      height: 44px;
      width: 100%;
      padding: 0 15px;
      color: #333;

      &:focus {
        border: 1px solid #000000;
      }

      &::placeholder {
        color: #999;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      max-width: 30px;
    }
  }
`;

export const Value = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  grid-gap: 15px 20px;
  align-items: center;
  width: 100%;

  span {
    color: #323232;
    font-size: 17px;
    font-weight: bold;
    line-height: 21px;
    font-family: Archivo, sans-serif;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    max-width: 30px;
  }

  & + div {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #323232;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  min-height: 40px;
  padding: 20px 20px 0 20px;
  background: #fff;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    max-width: 70px;
    height: 40px;
    padding: 0 10px;
    font-size: 15px;
    font-weight: bold;
    background: ${lighten(0.2, '#323232')};
    border: 0;
    border-radius: 4px;

    &:hover {
      background: ${lighten(0.1, '#323232')};
    }
  }

  button + button {
    margin-left: 15px;
    background: ${lighten(0.05, '#999')};
    border: 0;
    border-radius: 4px;

    &:hover {
      background: ${darken(0.05, '#ddd')};
    }
  }
`;

export const ValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;

  @media (max-width: 500px) {
    flex-direction: column;

    & > button:last-of-type {
      margin: 20px 0 0 0 !important;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 0 15px;
    height: 40px;
    background: ${lighten(0.3, '#000000')};
    border: 0;
    border-radius: 4px;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background: ${lighten(0.2, '#000000')};
    }
  }
`;
