/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import api from '~/services/api';

import { Container } from './styles';
import Loader from '~/components/Loader';
import Table from '~/components/Table';
import CreateButton from '~/components/Form/Button/Create';
import EditButton from '~/components/Form/Button/Edit';
import DeleteButton from '~/components/Form/Button/Delete';
import Modal from '~/components/Modal/Delete';

export default function List() {
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [collabs, setCollabs] = useState([]);
  const [loaderClients, setLoaderClients] = useState(true);
  const [loaderUsers, setLoaderUsers] = useState(true);
  const [loaderCollabs, setLoaderCollabs] = useState(false);
  const [deleteUser, setDeleteUser] = useState(null);
  const [showModalUser, setShowModalUser] = useState(false);
  const [showModalClient, setShowModalClient] = useState(false);

  function handleUserModal(id) {
    setDeleteUser(id);
    setShowModalUser(!showModalUser);
    setShowModalClient(false);
  }

  function handleClientModal(id) {
    setDeleteUser(id);
    setShowModalClient(!showModalClient);
    setShowModalUser(false);
  }

  const getClients = async () => {
    setLoaderClients(true);

    try {
      const response = await api.get('/clients');

      setClients(
        response.data.map(client => ({
          id: client.id,
          name: client.name,
          edit: <EditButton url={`/members/edit/${client.id}/client`} />,
          delete: <DeleteButton func={() => handleClientModal(client.id)} />,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar clientes!');
    } finally {
      setLoaderClients(false);
    }
  };

  const getUsers = async () => {
    setLoaderUsers(true);

    try {
      const response = await api.get('/users');

      setUsers(
        response.data.map(user => ({
          id: user.id,
          name: user.user.name,
          email: user.user.email,
          client: user.client.name,
          edit: <EditButton url={`/members/edit/${user.user.id}/user`} />,
          delete: <DeleteButton func={() => handleUserModal(user.user.id)} />,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar clientes!');
    } finally {
      setLoaderUsers(false);
    }
  };

  const getCollabs = async () => {
    setLoaderCollabs(true);

    try {
      const response = await api.get(`/collabs`);

      setCollabs(
        response.data.map(collab => ({
          id: collab.user.id,
          name: collab.user.name,
          email: collab.user.email,
          moderator: collab.moderator.name,
          edit: <EditButton url={`/members/edit/${collab.user.id}/collab`} />,
          delete: <DeleteButton func={() => handleUserModal(collab.user.id)} />,
        }))
      );
    } catch (err) {
      toast.error('Erro ao carregar colaboradores!');
    } finally {
      setLoaderCollabs(false);
    }
  };

  useEffect(() => {
    getClients();
    getUsers();
    getCollabs();
  }, []);

  async function handleDeleteUser() {
    try {
      setShowModalUser(false);
      const response = await api.get(`/users/${deleteUser}/details`);

      if (response.data.type.type !== 'collab') {
        const res = await api.get(`/relationusers/${response.data.id}`);

        if (res.data !== null) {
          toast.warn(
            'Você não pode excluir usuários que possuem colaboradores.'
          );
          return false;
        }
        setLoaderUsers(true);
      } else {
        setLoaderCollabs(true);
      }

      await api.delete(`/users/${deleteUser}`);

      if (response.data.type.type !== 'collab') {
        setUsers(users.filter(user => user.id !== deleteUser));
      } else {
        setCollabs(collabs.filter(collab => collab.id !== deleteUser));
      }

      setLoaderUsers(false);
      setLoaderCollabs(false);
      setDeleteUser(null);
      toast.success('Usuário excluído com sucesso.');
    } catch (err) {
      setLoaderUsers(false);
      setLoaderCollabs(false);
      setDeleteUser(null);
      toast.error('Erro ao excluir usuário, confira os dados!');
    }
    return true;
  }

  async function handleDeleteClient() {
    try {
      setShowModalClient(false);
      setLoaderClients(true);

      await api.delete(`/clients/${deleteUser}`);

      setClients(clients.filter(client => client.id !== deleteUser));

      setLoaderClients(false);
      setDeleteUser(null);
      toast.success('Cliente excluído com sucesso.');
    } catch (err) {
      setLoaderClients(false);
      setDeleteUser(null);
      toast.error('Erro ao excluir cliente, confira os dados!');
    }
  }

  const headerClients = ['Nome', 'Editar', 'Deletar'];
  const headerUsers = ['Nome', 'E-mail', 'Cliente', 'Editar', 'Deletar'];
  const headerCollabs = ['Nome', 'E-mail', 'Associado', 'Editar', 'Deletar'];

  return (
    <>
      <Modal
        show={showModalUser}
        setState={setShowModalUser}
        func={handleDeleteUser}
      />
      <Modal
        show={showModalClient}
        setState={setShowModalClient}
        func={handleDeleteClient}
      />

      <Container>
        <CreateButton url="/members/create/client" text="Adicionar cliente" />

        {loaderClients ? (
          <Loader />
        ) : (
          <Table
            title="Clientes"
            header={headerClients}
            data={clients}
            size="500px"
          />
        )}

        <hr />

        <CreateButton url="/members/create/user" text="Adicionar usuário" />

        {loaderUsers ? (
          <Loader />
        ) : (
          <Table
            title="Usuários"
            header={headerUsers}
            data={users}
            size="650px"
          />
        )}

        <hr />

        <CreateButton
          url="/members/create/collab"
          text="Adicionar colaborador"
        />

        {loaderCollabs ? (
          <Loader />
        ) : (
          <Table
            title="Colaboradores"
            header={headerCollabs}
            data={collabs}
            size="650px"
          />
        )}
      </Container>
    </>
  );
}
