import styled, { css } from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  background: #fff;
  padding: 0 30px;
  height: 70px;
  border-bottom: 3px solid #b89b29;

  @media (max-width: 820px) {
    padding: 10px 20px;
    height: 110px;
  }

  @media (max-width: 540px) {
    height: 70px;
  }

  @media (max-width: 450px) {
    height: 120px;
  }
`;

export const Content = styled.div`
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 820px) {
    flex-direction: column;
  }

  div:first-child {
    min-width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    @media (max-width: 450px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'button-menu logo'
        'search search';
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;

      @media (max-width: 450px) {
        grid-area: button-menu;
      }
    }

    img {
      height: 55px;
      margin-right: 20px;

      @media (max-width: 450px) {
        grid-area: logo;
      }
    }
  }

  aside {
    min-width: 330px;
    width: 530px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      display: flex;

      @media (max-width: 540px) {
        display: none !important;
      }
    }
  }
`;

export const SearchBox = styled.div`
  background: ${lighten(0.2, '#a0a0a0')};
  min-width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: padding 0.4s ease;

  @media (max-width: 450px) {
    grid-area: search;
  }

  input:focus ~ & {
    background: blue !important;
  }

  ${props =>
    props.hasEmpty &&
    css`
      padding: 10px !important;
      justify-content: space-between !important;

      input {
        padding-left: 5px !important;
        width: 240px !important;

        &::placeholder {
          color: #000000 !important;
        }
      }

      button {
        background: #fff !important;
      }
    `}

  &:hover {
    padding: 10px !important;
    justify-content: space-between !important;

    input {
      padding-left: 5px;
      width: 240px;

      &::placeholder {
        color: #000000;
      }
    }

    button {
      background: #fff;
    }
  }

  input {
    border: none;
    background: none;
    outline: none;
    padding: 0;
    color: #000000;
    font-size: 16px;
    line-height: 40px;
    width: 0;
    transition: all 1s ease;

    @media (max-width: 305px) {
      max-width: 150px !important;
    }

    &::placeholder {
      color: ${lighten(0.2, '#a0a0a0')};
      transition: color 0.3s ease;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      right: 5px;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${lighten(0.2, '#a0a0a0')};
    color: #000000;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    width: 30px;
    height: 30px;
    transition: all 0.7s ease;
  }
`;
